<template>
    <Toast position="bottom-right" />
    <Card>
        <template #title>
            <div class="flex flex-column w-full">
                <span> CARRERA: {{ career.carrera }}</span>
                <span class="text-sm font-s text-blue-300">{{ career.ie_id }} : {{ career.institucion_educativa }} </span> 
            </div>
        </template>
        <template #content>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <Panel header="Datos Generales de la Carrera " toggleable>
                       
                        <div class="grid">
                            <div class="col-6">
                                <table class="table table-sm">
                                    <tbody>
                                       
                                        <tr>
                                            <td class=" text-end "><strong>Nombre:</strong> </td>
                                            <td class="">{{ career.carrera }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Area:</strong> </td>
                                            <td class=""> {{ career.area }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Resolución:</strong> </td>
                                            <td class=""> {{ career.numero_resolucion }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Fecha Resolución:</strong></td>
                                            <td class=""> {{ $filters.dateFormat(career.fecha_resolucion) }}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>

                            </div>
                            <div class="col-6">
                                <table class="table table-sm">
                                    <tbody>
                                       
                                        <tr>
                                            <td class=" text-end "><strong>Tipo Tramite:</strong></td>
                                            <td class=""><Tag severity="warning" :value="career.tipo_tramite" rounded ></Tag></td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "> <strong>Nivel de Formación:</strong></td>
                                            <td class=""> {{ career.nivel_academico }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "> <strong>Tiempo de Estudio:</strong></td>
                                            <td class=""> {{ career.tiempo_estudio }}   </td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "> <strong>Regimen Estudio:</strong></td>
                                            <td class=""> 
                                                <Tag severity="success" :value="career.regimen_estudio" rounded v-if="career.regimen_estudio==='SEMESTRE'" ></Tag>
                                                <Tag severity="warning" :value="career.regimen_estudio" rounded v-else ></Tag>  
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                
                    </Panel>
                </div>

                <div class="field col-12 md:col-6" v-if="career">
                    <AcademicManagement @updateData="updateGestion"></AcademicManagement>
                </div>
                <div class="field col-12">
                    <div class="flex justify-content-between">
                        <SplitButton class="w-16rem" :label="state_selected?.estado??''" icon="pi pi-map" :model="items" v-if="career" />
                        <div class="flex justify-content-end">
                            <Button icon="pi pi-plus" label="Adicionar Resolución de Plan de Estudio" severity="secondary" size="small" @click="addResolution()" v-if="has_period && career" />
                            <Message v-else>Debe Adicionar un Periodo Academico</Message>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-12" v-for="(item , index) in study_resolutions " :key="index" >
                    <Panel :header="`Resolución de Plan de Estudio ${item.numeroResolucion} ${item.activo?'':''}`" toggleable :collapsed="true" 
                        :pt="{
                        header: { class: 'bg-secondary' },
                        title: { class: 'text-white' },
                        icons: { class: 'text-white'},
                        toggler: { class: 'text-white hover:bg-primary-reverse' }
                    }"    
                    >
                        <template #header>
                            <div class="flex justify-content-between">
                                <Tag value="Primary" class="mr-2"> {{ item.estado_instituto?.estado ?? '' }}</Tag>
                                <span class="text-white font-semibold"> {{ `Resolución de Plan de Estudio
                                    ${item.numeroResolucion} ${item.activo ? '' : ''}` }} </span>
                            </div>
                        </template>
                        <template #icons>
                            <span class=" text-green-300 text-lg font-bold mr-2 "> {{ periodo?.periodo === 'Anual'?'':`${periodo?.periodo} -` }} {{ gestion?.gestion }}</span>
                            
                            <button class="p-panel-header-icon p-link mr-2 kicon-panel-info" @click="checkHistoryStudyPlan(item) " >
                                <i class='bx bx-history '></i>
                            </button>
                            <button class="p-panel-header-icon p-link mr-2 kicon-panel-info" @click="editResolutionPlan(item) " v-if="(item.numeroResolucion !== '0210/2023' || item.numeroResolucion !== '0637/2019') && item.estado_instituto?.estado !== 'APROBADO'" >
                                <i class='bx bxs-pencil '></i>
                            </button>
                            <button class="p-panel-header-icon p-link mr-2 kicon-panel-danger"
                                @click="deleteResolution(item.plan_studio_resolucion_id)"
                                v-if='item.asignaturas.length <= 0 && item.estado_instituto?.estado === "CREADO"'>
                                <i class='bx bxs-trash-alt '> </i>
                            </button>
                            <button class="p-panel-header-icon p-link mr-2 kicon-panel-danger"
                                @click="sendStudyPlan(item)" v-if="item.estado_instituto?.estado === 'CREADO' || item.estado_instituto?.estado === 'OBSERVADO' || item.estado_instituto?.estado === 'OBSERVADO DDE'">
                                <i class='bx bxs-send '> </i>
                            </button>
                          
                            
                        </template>
                        <!-- {{ JSON.stringify(item) }} -->

                        <div class="formgrid grid">

                            <div class="field col-12 md:col-4 flex align-items-center">
                                <strong>Fecha: </strong> {{ formatDate(item.fechaResolucion) }}
                            </div>
                            <div class="field col-12 md:col-4 flex align-items-center ">
                                <!-- <div class="kicon-button"  v-tooltip.left="'Editar Denominacion y Mención'" @click="editResolutionPlan(item)"  v-if="(item.numeroResolucion !== '0210/2023' || item.numeroResolucion !== '0637/2019') && item.estado_instituto?.estado !== 'APROBADO'"  >
                                    <i class="duotone icon-pencil " ></i>
                                </div> -->
                                <strong>Denominación: </strong> {{ item.denominacion }}
                            </div>
                            <div class="field col-12 md:col-4 flex align-items-center">
                                <strong>Mención: </strong> {{ item.descripcion }}
                            </div>
                            
                            <div class="col-6 md:col-6  ">
                                
                                <Button icon="pi pi-plus" label="Adicionar Plan de Estudio" severity="success" size="small" @click="addStudyPlan(item.id)" v-if='item.asignaturas.length<=0 '  />
                                <div v-else>
                                    <div class="flex  align-content-center">
                                        <div class="kicon-button"  v-tooltip.left="'Editar Plan de Estudio'" @click="editStutyPlan(item)"
                                        
                                        v-if="!item.has_academic_offert && item.numeroResolucion !== '0210/2023' && item.numeroResolucion !== '0637/2019' && (item.estado_instituto?.estado === 'CREADO' || item.estado_instituto?.estado === 'OBSERVADO'  || item.estado_instituto?.estado === 'OBSERVADO DDE') ">

                                            <i class="duotone icon-pencil " ></i>
                                        </div>
                                        <strong class="pl-2 pt-2">PLAN DE ESTUDIO </strong> 

                                    </div>
                                
                                </div>

                            </div>
                          

                            <div class="field col-6 md:col-6 flex justify-content-end   " v-if="item.asignaturas.length>0">
                                <!-- <div class="pr-2" v-if = "chekAcademicOffert(item)">
                                    <div v-if=" checkTeachersAcademicOffert(item)" >
                                      
                                    </div>
                                </div>
                                <div v-else>
                                    <Button icon="pi pi-plus" label="Adicionar Oferta Academica" severity="success" 
                                        size="small" @click="addAcademicOffert(item.plan_estudio_carrera_id)"
                                        v-if="item.estado_instituto?.estado === 'APROBADO'"  />
                                </div> -->

                                <div class="pr-2" v-if="!item.has_academic_offert">
                                    <Button icon="pi pi-plus" label="Adicionar Oferta Academica" severity="success" 
                                    size="small" @click="showAcademicOffertTeachers(item.plan_estudio_carrera_id)"
                                    v-if="item.estado_instituto?.estado === 'APROBADO'"  />
                                </div>
                                <div v-if="item.has_academic_offert" >
                                    
                                    <Button icon="pi pi-eye" label="Ver Oferta" severity="secondary" size="small" @click="showAcademicOffert(item.plan_estudio_carrera_id)" v-if="item.has_teachers"  />
                                    <Button icon="bx bxs-user-plus" label="Asignar Maestros" severity="info" size="small" @click="showAcademicOffertTeachers(item.plan_estudio_carrera_id)" v-else  />
                                </div>
                            </div>
                            
                            
                            <!-- {{ JSON.stringify(item.asignaturas) }} -->
                            <div class="field col-12 md:col-4" v-for="(asignatura, index) in item.asignaturas" :key="index" >
                               <Panel :header="asignatura.name">
                                    <template #header>
                                        <div class="flex justify-content-between w-full">
                                            <div class="font-bold">{{ asignatura.name }}</div>
                                            <div class="font-bold text-primary"> Hrs Semana {{ getTotalHours(asignatura.semestres) }} </div>
                                        </div>
                                    </template>
                                    <table class="table">
                                        <thead>
                                            <th>Código</th>
                                            <th>Asignatura</th>
                                            <th>Horas</th>
                                            <th>Prerequisito</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(subject,index) in asignatura.semestres " :key="index" >
                                                <td>{{ subject.abreviacion}}</td>
                                                <td>{{ subject.asignatura }}</td>
                                                <td>{{ subject.horas }} </td>
                                                <td>{{ subject.prerequisito?subject.prerequisito.anteriorPlanEstudioAsignatura.asignaturaTipo.abreviacion:''}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                               </Panel> 
                               
                            </div>
                        </div>
                    </Panel>

                </div>
               
            </div>
            
        </template>
    </Card>

    <Dialog v-model:visible="dialog" modal :header="study_resolution.id?'Editar Resolución de Plan de Estudio':'Registro de Resolución de Plan de Estudio'" :style="{ width: '50vw' }">
        <Message v-if="!study_resolution.id">Las Resoluciones de Plan de Estudio <strong>0210/2023 , 0637/2019</strong>  ya se encuentran disponibles en el listado de resoluciones de planes de Estudio vigentes </Message>
        <div class="formgrid grid">
            
            
            <div class="col" v-if="!study_resolution.id" >
                    <label >Desea usar una Resolucion de Plan de Estudio General <strong>0210/2023 o 0637/2019</strong>?</label>
                    <SelectButton v-model="current_resolution_question" :options="options" aria-labelledby="basic" />
            </div>
            
            <Divider layout="vertical" v-if="!study_resolution.id" />
            <div class="col " v-if="current_resolution_question=='SI'" >
                Resoluciones de planes de estudio vigentes:
                <br>
                <br>   
                <div v-for="(item, index ) in current_resolutions" :key="index" class="flex align-items-center">
                    
                    <RadioButton v-model="current_resolution"  :value="item"  />
                    <label class="ml-3 mt-2"> <strong>{{ item.numero_resolucion }} <Tag severity="info" :value="item.denominacion" v-if="item.denominacion"></Tag> <Tag severity="success" :value="item.mencion" v-if="item.mencion"></Tag> </strong> </label>
                </div>
                
                <!-- <Listbox v-model="current_resolution" :options="current_resolutions" optionLabel="numero_resolucion" class="w-full md:w-14rem" /> -->
            </div>
            <div :class="study_resolution.id ? 'field col-12 md:col-12' : 'field col-12 md:col-8'" v-if="current_resolution_question=='NO' || study_resolution.id" >
                <div class="formgrid grid">
                    <div class="field col-12 md:col-12">
                        <label >Numero de Resolución</label>
                        <InputMask id="basic" v-model="study_resolution.numero_resolucion" :disabled="study_resolution.id"  class="p-inputtext-sm w-full" mask="9999/9999" placeholder="0000/yyyy" />
                     

                        <!-- <InputText type="text"   /> -->
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Fecha de Resolución</label>
                        <Calendar class="p-inputtext-sm w-full" v-model="study_resolution.fecha_resolucion"  dateFormat="dd/mm/yy" :disabled="study_resolution.id"/>
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Denominación del Titulo</label>
                        <InputText type="text" class="p-inputtext-sm w-full" v-model="study_resolution.denominacion" />
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Mención</label>
                        <InputText type="text" class="p-inputtext-sm w-full" v-model="study_resolution.descripcion" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="createStydeResolution()" autofocus v-if="!study_resolution.id" :disabled="button_desabled" />
            <Button label="Actualizar" icon="pi pi-check" @click="updateStudyResolution()" autofocus v-else :disabled="button_desabled" />
        </template>
    </Dialog>

    <Dialog v-model:visible="dialog_history" modal :header="`Historial del Plan de Estudio ${study_plan?.study_plan?.numeroResolucion}`" :style="{ width: '50vw' }">
        <Timeline :value="study_plan?.histories">
            <template #opposite="slotProps">
                <div class="flex flex-column">
                    <span> {{slotProps.item.estadoInstituto.estado}} </span>
                    <small class="p-text-secondary">{{slotProps.item.fechaRegistro}}</small>
                </div>
            </template>
            <template #content="slotProps">
                <div class="flex flex-column">
                    
                    <InlineMessage :severity="slotProps.item.estadoInstituto.id === 8 || slotProps.item.estadoInstituto.id == 4?'error':'info' ">{{slotProps.item.observacion  }}</InlineMessage>
                </div>
               
               
            </template>
        </Timeline>
    </Dialog>

    <Dialog v-model:visible="dialog_edit_resolution" modal :header="`Editar Resolución de Plan de Estudio `" :style="{ width: '40vw' }">
        <div class="formgrid grid">
            <div class="field col-12 md:col-12">
                <label >Numero de Resolución</label>
                <InputMask id="basic" v-model="resolution_plan_edit.numeroResolucion"   class="p-inputtext-sm w-full" mask="9999/9999" placeholder="0000/yyyy" />
                

                <!-- <InputText type="text"   /> -->
            </div>
            <div class="field col-12 md:col-12">
                <label >Fecha de Resolución</label>
                <Calendar class="p-inputtext-sm w-full" v-model="resolution_plan_edit.fecha_resolucion_date"  dateFormat="dd/mm/yy" />
            </div>
            <div class="field col-12 md:col-12">
                <label >Denominación del Titulo</label>
                <InputText type="text" class="p-inputtext-sm w-full" v-model="resolution_plan_edit.denominacion" />
            </div>
            <div class="field col-12 md:col-12">
                <label >Mención</label>
                <InputText type="text" class="p-inputtext-sm w-full" v-model="resolution_plan_edit.descripcion" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog_edit_resolution = false" text />
            <Button label="Actualizar" icon="pi pi-check" @click="updateResolutionPlan()" />
        </template>
    </Dialog>
 
</template>
<script>
import CareerDataServie from '@/services/CareerDataServie';
import AcademicManagement from '@/components/AcademicManagement.vue';
import AcademicOffertDataService from '@/services/AcademicOffertDataService';
import EstadoInstitutoDataService from '@/services/EstadoInstitutoDataService';
import OperativeCareerDS from '@/services/OperativeCareerDS';
import SubDirectionDataService from '@/services/SubDirectionDataService';
import CareerStudyPlanDataService from '@/services/CareerStudyPlanDataService';


export default
{
    data:()=>({
        career:{},
        dialog:false,
        current_resolution_question:'',
        current_resolution:null,
        current_resolutions:[],
        study_resolution:{},
        study_resolutions:[],
        states: [],
        options:['SI','NO'],
        current_period: null,
        state_selected: null,
        gestion: null,
        periodo: null,
        has_period: false,
        button_desabled: false,
        dialog_history: false,
        study_plan: null,
        dialog_edit_resolution: false,
        resolution_plan_edit: null,
    }),
    mounted(){
        // this.getCareer()
        // this.getCurrentResolutions()
        
        // this.getCurrentPeriod()
        // this.chekPeriod()
        Promise.all([this.getOperativeActive(),this.getEstados(),this.getCareer(),this.reloadPage()])
        
    },
    methods:
    {
        async reloadPage()
        {
            await Promise.all([ 
                
                this.getCurrentResolutions(),
                // this.getCurrentPeriod(),
                this.chekPeriod(),
                // this.updateSubjects()
               
            ])
            
            console.log('reload finish')
        },

        editResolutionPlan(plan_studio_resolucion)
        {
            this.resolution_plan_edit = Object.assign({}, plan_studio_resolucion)
            console.log ( this.resolution_plan_edit)
            
            this.resolution_plan_edit.fecha_resolucion_date =new Date(`${plan_studio_resolucion.fechaResolucion}T00:00:00`) 
            this.dialog_edit_resolution = true
        },
        async updateResolutionPlan(){

            // let new_date = `${this.resolution_plan_edit.fecha_resolucion_date.getUTCFullYear()}-${this.resolution_plan_edit.fecha_resolucion_date.getUTCMonth() + 1}-${this.resolution_plan_edit.fecha_resolucion_date.getUTCDate()}` 
            let new_date = this.resolution_plan_edit.fecha_resolucion_date.toISOString().split('T')[0];
            console.log(new_date)
            console.log(this.resolution_plan_edit.fechaResolucion)

            let payload = {
                denominacion: this.resolution_plan_edit.denominacion,
                descripcion: this.resolution_plan_edit.descripcion,
                numero_resolucion: this.resolution_plan_edit.numeroResolucion
            }
            if(new_date !== this.resolution_plan_edit.fechaResolucion)
            {
                console.log('diff XD')
                payload.fecha_resolucion = new_date
            }
          
            console.log('actualizand',payload);
            let response = await CareerStudyPlanDataService.update(this.resolution_plan_edit.id, payload)
            console.log(response.data)
            if(response.data.statusCode === 201 )
            {
                this.$toast.add({ severity: 'info', summary: 'Confirmación', detail: `Se edito la informacion del plan de estudio ${this.resolution_plan_edit.numeroResolucion}`, life: 3000 });
            }else{
                this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
            this.resolution_plan_edit = null
            this.dialog_edit_resolution = false
            Promise.all([this.getOperativeActive(),this.getEstados(),this.getCareer(),this.reloadPage()])
        },

        async getOperativeActive(){
            let response = await OperativeCareerDS.getOperativeActive(this.$route.params.id)   
            // console.log('operativo activo',response.data)
            this.periodo = response.data.periodoTipo
            this.gestion = response.data.gestionTipo
            // this.updateData()
            // this.loadOperativos()
            await Promise.all([this.getStudyResolutions()])
            // this.getStudyResolutions()
            // this.updateSubjects()
               
        },


        updateGestion(data){
            console.log('actualizando la gestion',data)
            this.gestion = data.gestion
            this.periodo = data.periodo
            this.updateSubjects()
            this.chekPeriod()
            // this.reloadPage() 
        },

        async updateSubjects()
        {
            console.log('update subjects')
            
            await Promise.all(this.study_resolutions.map( async( plan )=>{
                // this.getSubjects(plan)

                let response = await AcademicOffertDataService.getSubjects(plan.plan_estudio_carrera_id,this.gestion.id, this.periodo.id)
                // console.log(`${plan.plan_estudio_carrera_id} length `,response.data.data.length )
                if(response.data.data.length > 0)
                {
                    plan.has_academic_offert = true
                }else{
                    plan.has_academic_offert = false
                }
                response = await AcademicOffertDataService.getShowSubjects(plan.plan_estudio_carrera_id,this.gestion.id, this.periodo.id)

                if(response.data.data.length > 0)
                {
                    plan.has_teachers = true
                }else{
                    plan.has_teachers = false
                }

                // console.log(`${plan.numeroResolucion} has offert `,plan.has_academic_offert )
                // console.log(`${plan.numeroResolucion} has teachers `,plan.has_teachers )
                return plan
            }))
            // console.log('resoluciones actualizadas', this.study_resolutions)
        },

        getTotalHours(list)
        {
            let hours = 0
            list.forEach(element => {
                // console.log(element)
                hours += parseInt(element.horas)
            });
            // console.log(list)
            return hours

        },

        getCareer()
        {
            CareerDataServie.get(this.$route.params.id)
                .then((response)=>{
                    this.career = response.data.data
                    console.log('career ===>>',response)
                })
        },
        getCurrentResolutions()
        {
            CareerDataServie.getCurrentResolutions(this.$route.params.id)
                .then((response)=>{
                    this.current_resolutions = response.data.data
                    console.log('current resolution',response.data)
                })
        },
        // getCurrentPeriod() {
        //     CareerDataServie.getCurrentPeriod(this.$route.params.id)
        //         .then((response) => {
        //             this.current_period = response.data.data
        //             // console.log(response.data.data)
                    
        //             this.getStudyResolutions()
        //         })
        // },
        async getEstados()
        {
            let response = await EstadoInstitutoDataService.getAll()
            // console.log('asdasdas asd asd a',response.data)
            this.states = response.data
        },
        sendStudyPlan(item) {
            // console.log('items ',item)
            if(item.asignaturas.length > 0){

                if(item.estado_instituto.estado === 'OBSERVADO')
                {

                    this.$confirm.require({
                        message: 'Esta de acuerdo con enviar el Plan de Estudio para su aprobacion al DGESTTLA ?',
                        header: 'Enviar Plan de Estudio',
                        icon: 'bx bxs-send',
                        acceptClass: 'p-button-success',
                        acceptLabel: 'Enviar',
                        rejectLabel: 'Cancelar',
                        accept: async () => {
                            let payload = {
                                estadoInstitutoId: 2,// enviado a revision
                                planEstudioCarreraId: item.id,
                                observacion: "ENVIADO A REVISION"
                            }
                            let response = await CareerDataServie.updateStateStudyPlan(payload)
                            this.getStudyResolutions()
                            if (response.data.statusCode === 500) {
                                this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
    
                            } else {
    
                                this.$toast.add({ severity: 'info', summary: 'Confirmación', detail: `Se Envio el Plan Esudio ${item.id}`, life: 3000 });
                            }
                        },
                        reject: () => {
                            // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                        }
                    });

                }else{

                    this.$confirm.require({
                        message: 'Esta de acuerdo con enviar el Plan de Estudio para su aprobacion a la DDE ?',
                        header: 'Enviar Plan de Estudio',
                        icon: 'bx bxs-send',
                        acceptClass: 'p-button-success',
                        acceptLabel: 'Enviar',
                        rejectLabel: 'Cancelar',
                        accept: async () => {
                            let payload = {
                                estadoInstitutoId: 6,// enviado a revision
                                planEstudioCarreraId: item.id,
                                observacion: "ENVIADO A REVISION DDE"
                            }
                            let response = await CareerDataServie.updateStateStudyPlan(payload)
                            this.getStudyResolutions()
                            if (response.data.statusCode === 500) {
                                this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
    
                            } else {
    
                                this.$toast.add({ severity: 'info', summary: 'Confirmación', detail: `Se Envio el Plan Esudio ${item.id}`, life: 3000 });
                            }
                        },
                        reject: () => {
                            // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                        }
                    });
                }
            }else{
                this.$toast.add({ severity: 'info', summary: 'Error', detail: `No se puede enviar un plan de estudio vacio`, life: 3000 });
            }
            // console.log('plan estudio carrera', item.id)
        },
        async createEstadoInstituto(plan_estudio_carrera_id)
        {
            let payload =   {
                                estadoInstitutoId: 1,//creado
                                planEstudioCarreraId: plan_estudio_carrera_id,
                                observacion: "CREADO"
                            }
            let response = await CareerDataServie.updateStateStudyPlan(payload)
            // console.log('update state',response.data)
        },
        async updateState(plan_estudio_carrera_id,id)
        {   
            let payload =   {
                                estadoInstitutoId: id,//creado
                                planEstudioCarreraId: plan_estudio_carrera_id,
                                observacion: "ACTUALIZADO POR SISTEMA"
                            }
            let response = await CareerDataServie.updateStateStudyPlan(payload)
            // console.log('update state',response.data)
        },
        async getStudyResolutions() {
            /** Actualiza la lista de planes de estudio
             * 
             */
        // let response = await EstadoInstitutoDataService.getAll()
        // console.log('seteando estados',response.data)
        // this.states = response.data

        let response = await CareerDataServie.getResolutions(this.$route.params.id)
        // console.log('planes',response.data)
        response.data.forEach( async (plan) =>{
            if(!plan.planEstudioCarrera.estadoInstituto)
            {
                if(plan.planEstudioCarrera.aprobado)
                {
                await this.updateState(plan.planEstudioCarrera.id,3) //3 aprobado
                }else{
                    await this.updateState(plan.planEstudioCarrera.id,1) //1 creado
                }
            }
        })

        // este codigo extenso solo da formato para los paneles de resolucion 
        CareerDataServie.getResolutions(this.$route.params.id)
            .then(async (response) => {
                this.study_resolutions = []
                let study_plans = []
                // console.log('resolutions', response.data)


                response.data.forEach((plan) => {

                    let new_plan = {
                        id: plan.planEstudioCarrera.id,
                        plan_studio_resolucion_id: plan.planEstudioCarrera.planEstudioResolucion.id,
                        plan_estudio_carrera_id: plan.id,
                        numeroResolucion: plan.planEstudioCarrera.planEstudioResolucion.numeroResolucion,
                        fechaResolucion: plan.planEstudioCarrera.planEstudioResolucion.fechaResolucion,
                        activo: plan.planEstudioCarrera.planEstudioResolucion.activo,
                        descripcion: plan.planEstudioCarrera.descripcion,
                        denominacion: plan.planEstudioCarrera.denominacion,
                        estado_instituto: plan.planEstudioCarrera.estadoInstituto,
                        has_academic_offert: false,
                        has_teachers: false,

                    }
                    // console.log(new_plan)
                    /** 
                     * estados que dependen de la gestion y periodo academico
                    */
                    // this.getSubjects(new_plan)
                    let asignaturas = plan.planEstudioCarrera.planesAsignaturas

                    let formato_asigunaturas = []
                    let prerequisito = null
                    asignaturas.forEach(asignatura => {


                        asignatura.planesAsignaturasReglas.forEach(element => {
                            prerequisito = element
                        });

                        formato_asigunaturas.push(
                            {
                                horas: asignatura.horas,
                                asignatura: asignatura.asignaturaTipo.asignatura,
                                abreviacion: asignatura.asignaturaTipo.abreviacion,
                                regimenGrado: asignatura.regimenGradoTipo.regimenGrado,
                                prerequisito: prerequisito,
                                index: asignatura.index

                            }
                        )
                        prerequisito = null
                    });
                    // console.log('asignaturas agrupadas',formato_asigunaturas)                                        


                    let name = ''
                    let tiempo = []
                    let semestres = []
                    // let names =[]

                    formato_asigunaturas.forEach((element, index) => {
                        if (element.regimenGrado === name) {
                            semestres.push(element)
                        } else {
                            if (semestres.length > 0) {
                                tiempo.push(
                                    {
                                        name: name,
                                        semestres: semestres
                                    }
                                )
                                semestres = []
                            }
                            name = element.regimenGrado
                            semestres.push(element)

                            //   names.push(name)
                        }
                        if (index === formato_asigunaturas.length - 1) {
                            tiempo.push(
                                {
                                    name: name,
                                    semestres: semestres
                                }
                            )
                        }
                        // console.log(element.regimenGrado)
                    });
                    // console.log('formato')
                    tiempo.forEach(element => {
                        // console.log('default', element.semestres)
                        element.semestres.sort( (a,b)=> parseInt(a.index ?? 0) - parseInt(b.index ?? 0))
                        // console.log('order',element.semestres)
                        return element
                    });

                    new_plan.asignaturas = tiempo
                    // console.log('tiempo',tiempo)
                    study_plans.push(new_plan)
                });
                this.study_resolutions = study_plans
                // console.log('resolutions', this.study_resolutions)
                this.items = []
                // console.log('states ================>', this.states)
                // aqui hacer la separacion de los modulos  
                //
                await Promise.all(
                    this.states.map(async (state) => {
                    // console.log('estado ...', state.estado)
                    state.study_plans = this.study_resolutions.filter((o)=>{ return o.estado_instituto?.estado === state.estado })
                    // console.log(state.study_plans)
                    return state;
                    })
                )

                // console.log('estados',this.states)
                
                await Promise.all(
                    this.states.map(async (state) => {
                    // console.log('estado ---', state.estado)
                    
                    this.items.push({
                        label: state.estado + ' ('+ state.study_plans.length+')' ,
                        icon: 'pi pi-external-link',
                        command: () => {
                            this.setState(state) 
                            }
                    })

                    if(state.estado === 'APROBADO')
                    {
                    this.setState(state)
                    }

                    })
                )
                this.updateSubjects()
                // console.log(this.states)


                // console.log('Study plan', study_plans)

            })
        },
        setState(new_state)
        {
            this.state_selected = new_state
            this.study_resolutions = new_state.study_plans
        },
        createStydeResolution() {
            //Si va usar una resolucion vigente
            this.button_desabled = true
            if (this.current_resolution_question == 'SI') {
                let payload = {
                    carrera_autorizada_id: parseInt(this.$route.params.id),
                    plan_estudio_carrera_id: parseInt(this.current_resolution.plan_estudio_carrera_id)
                }
                console.log('ingresando por si')
                CareerDataServie.createCarrerStudyResolutionByActive(payload)
                    .then((response) => {
                        console.log('save resolution SI', response.data)
                        console.log('response SI',response.data.data.planEstudioCarrera.planEstudioResolucion.numeroResolucion)
                        
                        // console.log('numero resolucion',response.data.planEstudioCarrera.planEstudioResolucion.numeroResolucion)
                        // window.location.reload();
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Se registro la Resolucion', life: 3000 })
                        this.dialog = false

                        if(response.data.data.planEstudioCarrera.planEstudioResolucion.numeroResolucion === '0210/2023' || response.data.data.planEstudioCarrera.planEstudioResolucion.numeroResolucion === '0637/2019')
                        {
                             // aqui mandar aprobado en caso de que sea la 210
                            this.updateState(response.data.data.planEstudioCarreraId,3).then((result)=>{
                                console.log('result',result)
                                this.reloadPage()
                                this.getStudyResolutions()
                                this.button_desabled = true
                            }) 

                            this.study_resolution = {}
                            

                        }


                        // if(response.data.data.planEstudioCarrera.planEstudioResolucion.numeroResolucion === '0210/2023' || response.data.data.planEstudioCarrera.planEstudioResolucion.numeroResolucion === '0637/2019')
                        // {
                        //      // aqui mandar aprobado en caso de que sea la 210
                        //     this.updateState(response.data.data.planEstudioCarreraId,3).then((result)=>{
                        //         console.log('result',result)
                        //         this.reloadPage()
                        //         this.getStudyResolutions()
                        //         this.button_desabled = true
                        //     }) 

                        //     this.study_resolution = {}
                            

                        // }else{
                        //      // aqui mandar aprobado en caso de que sea la 210
                        //     this.createEstadoInstituto(response.data.data.planEstudioCarreraId).then((result)=>{
                        //         console.log('result',result)
                        //         this.reloadPage()
                        //         this.getStudyResolutions()
                        //         this.button_desabled = true
                        //     }) 

                        //     this.study_resolution = {}
                        //     // this.reloadPage()
                        // }
                       
                    })
                    .catch((error)=>{
                        console.log('error : ',error)
                    } )
                

            } else { //NO



            //chek if not are 210 resolution
            
            let rm210 = this.study_resolution.numero_resolucion.includes('210')
            if(rm210)
            {
                return this.$toast.add({ severity: 'info', summary: 'No pudo registra la Resolucion ', detail: 'No puede registrar la resolucion 210 o 637 por este medio, debe seleccionar la resolucion de la lista por SI', life: 3000 })
            }

            /*let rm637 = this.study_resolution.numero_resolucion.includes('637')
            if(rm637)
            {
                return this.$toast.add({ severity: 'info', summary: 'No pudo registra la Resolucion ', detail: 'No puede registrar la resolucion 210 o 637 por este medio, debe seleccionar la resolucion de la lista por SI', life: 3000 })
            }*/

            let payload = {
                numero_resolucion: this.study_resolution.numero_resolucion,
                fecha_resolucion: this.dateToDMY(this.study_resolution.fecha_resolucion),
                descripcion: this.study_resolution.descripcion,
                denominacion: this.study_resolution.denominacion,
                carrera_autorizada_id: parseInt(this.$route.params.id)
            }

            CareerDataServie.createCarrerStudyResolution(payload)
                    .then((response) => {
                        // console.log('save resolution NO', response.data)
                        this.getStudyResolutions()
                        // window.location.reload
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Se registro la Resolucion', life: 3000 })
                        this.dialog = false
                        this.study_resolution = {}
                        this.reloadPage()
                        this.createEstadoInstituto(response.data.data.plan_estudio_carrera_id).then((result)=>{
                            console.log(result)
                            this.getStudyResolutions()
                            this.button_desabled = true
                        })

                        }) 
            }

            
        },


        editStudyResolution(item){
            // console.log(item)
            this.study_resolution = {}
            this.study_resolution.numero_resolucion = item.numeroResolucion
            this.study_resolution.fecha_resolucion = item.fechaResolucion
            this.study_resolution.fecha_resolucion_date = new Date.parse(`${ item.fechaResolucion}T00:00:00.000Z`)
            this.study_resolution.descripcion = item.descripcion
            this.study_resolution.denominacion = item.denominacion
            this.study_resolution.id = item.id
            console.log('study_resolution', this.study_resolution)
            this.dialog = true
        },

        async updateStudyResolution(){
            let payload = {}
            payload.descripcion = this.study_resolution.descripcion
            payload.denominacion = this.study_resolution.denominacion
            this.button_desabled = true
            let response = await  AcademicOffertDataService.updateStudyPlanResolution(this.study_resolution.id, payload )
            if(response.data.statusCode === 201)
            {
                this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Se actualizo el Registro', life: 3000 })
            }else{
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo actualizar el registro', life: 3000 });
            }
            this.reloadPage()
            this.button_desabled = false
            // console.log('response', response.data)
            this.dialog = false


        },

        addResolution()
        {   
            this.study_resolution = {}
            this.button_desabled = false
            this.dialog=true
        },
        addStudyPlan(resolution_study_id)
        {
            this.$router.push(`/study_plan/${resolution_study_id}/${this.career.carrera_autorizada_id}`)
        },
        editStutyPlan(item)
        {
            //sava data on vuex storage
            //check if this for insitute and resolution study
            let study_plan_saved = item
            study_plan_saved.carrera_autorizada_id = this.career.carrera_autorizada_id
            this.$store.dispatch('saveStudyPlan',study_plan_saved)
            this.$router.push(`/study_plan/${item.id}/${this.career.carrera_autorizada_id}`)
        },
        addAcademicOffert(plan_estudio_carrera_id)
        {
            this.$router.push(`/academic_offert/${plan_estudio_carrera_id}/${this.career.carrera_autorizada_id}`)
        },
        showAcademicOffert(plan_estudio_carrera_id)
        {
            this.$router.push(`/academic_offert_show_teacher/${plan_estudio_carrera_id}/${this.career.carrera_autorizada_id}`)
        },
        showAcademicOffertTeachers(plan_estudio_carrera_id)
        {
            this.$router.push(`/academic_offert_show/${plan_estudio_carrera_id}/${this.career.carrera_autorizada_id}`)
        },

        chekPeriod()
        {   
            CareerDataServie.getPeriodosAcademicos(this.$route.params.id)
                .then((response)=>{
                    this.periodos_ademicos = response.data.data
                    // console.log('periodos academicos',response.data.data)
                    if(this.periodos_ademicos.length > 0)
                    {
                        this.has_period = true           
                    }
                })
           
        },
        async chekAcademicOffert(plan)
        {
            let has_academic_offert = false
            let response = await AcademicOffertDataService.getSubjects(plan.plan_estudio_carrera_id,this.gestion.id, this.periodo.id)
            // console.log('length:',response.data.data.length )
            if(response.data.data.length > 0)
            {
                has_academic_offert = true
            }
            return has_academic_offert
        },
        async checkTeachersAcademicOffert(plan){
            let has_teachers = false

            let response= await AcademicOffertDataService.getShowSubjects(plan.plan_estudio_carrera_id,this.gestion.id, this.periodo.id)

            if(response.data.data.length > 0)
            {
                has_teachers = true
            }
            return has_teachers
        },
        async getSubjects(plan)
        {
            let has_academic_offert = false
            let has_teachers = false
            let response = await AcademicOffertDataService.getSubjects(plan.plan_estudio_carrera_id,this.gestion.id, this.periodo.id)
            // console.log('length:',response.data.data.length )
            if(response.data.data.length > 0)
            {
                has_academic_offert = true
            }
            plan.has_academic_offert = has_academic_offert

            response= await AcademicOffertDataService.getShowSubjects(plan.plan_estudio_carrera_id,this.gestion.id, this.periodo.id)

            if(response.data.data.length > 0)
            {
                has_teachers = true
            }
            plan.has_teachers = has_teachers
            // console.log('checking:',has_academic_offert)    
            
        },
        dateToDMY(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1; //Month from 0 to 11
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
        },
        formatDate(dateString)
        {
            let fechaArray= dateString.split('-')
            
            return `${fechaArray[2]}/${fechaArray[1]}/${fechaArray[0]}`
        },

        deleteResolution(id) {
            this.$confirm.require({
                message: 'Se eliminara el plan de estudio, esta de acuerdo?',
                header: 'Eliminar Plan de Estudio',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Si',
                accept: async () => {
                    let response = await CareerDataServie.deleteResolution(id,this.career.carrera_autorizada_id)

                    // console.log('deletePlan', response.data)
                    
                    if(response.data.statusCode === 500)
                    {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });

                    }else{
                        this.$toast.add({ severity: 'info', summary: 'Confirmación', detail: 'Se elimino la resolucion del plan de estudio', life: 3000 });
                        this.reloadPage()
                    }
                },
                reject: () => {
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },

        async checkHistoryStudyPlan(data){
            console.log(data)
    
            let response = await SubDirectionDataService.getStudyPlanHistory(data.id)
            
           this.study_plan = {
                study_plan:  data,
                histories: response.data.data
            }
            this.dialog_history = true
            console.log(this.study_plan)
        }
        
    },
    components:{
        AcademicManagement
    }
}
</script>

<!-- para cambiar el colordel panel -->
<!-- <style scoped>
:deep(.p-panel .p-panel-header) {
    background-color: #e6f5ff;
    border-color: #b3d9ff;
    color: #002e4d;
}

:deep(.p-panel .p-panel-content) {
    border-color:  #b3d9ff;
}
</style> -->