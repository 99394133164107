
<template>
    
    <Toast position="bottom-right" />
    <Panel header="Calendario Académico" toggleable
    :pt="{
        header: { class: 'bg-primary' },
        title: { class: 'text-white' },
        toggler: { class: 'text-white hover:bg-primary-reverse' }
    }">
   <div class="flex justify-content-between">
    
        <div class="flex  align-items-center">
            <span class="font-bold text-lg text-blue-600 pl-2 pr-2">Periodo</span>
            <Dropdown v-model="periodo_active" :options="periodos" optionLabel="periodo" placeholder="Seleccionar periodo" class="w-full md:w-8rem" @change="loadOperativos()" />
            <span class="font-bold text-lg text-blue-600 pl-2 pr-2">Gestión</span>
            <Dropdown v-model="gestion_active" :options="gestions" optionLabel="gestion" placeholder="Seleccionar gestion" class="w-full md:w-8rem" @change="loadOperativos()" />
            
        </div>
        
        <div class="flex justify-content-end">
            <Button label="Nuevo Paralelo " severity="success" size="small" icon="pi pi-plus" @click="createParallel()"/>
            <div class="pl-1"></div>
            <!--<Button label="" v-tooltip.bottom="'Adicionar Recuperatorio Excepcional'" icon="bx bxs-layer-plus" severity="warning"  @click="generateExecptionalOperative()" v-if="!has_exeptional_recovery"></Button>-->
        </div>
   </div>
    <!-- {{ JSON.stringify(events) }} -->
    <div class="flex kadd-horizontal-scroll">
        <!-- <table class="table" v-if="gestion_active?.vigente"> -->
        <table class="table" v-if="gestion_active">
         <!--<thead>
             <th>Activo / Inactivo</th>
             
             <th>Operativo</th>
             <th>Etapa</th>
             <th>Fecha Inicio</th>
             <th>Fecha Fin</th>
             <th></th>
         </thead>-->
         <tbody>
             <tr v-for="(item, index) in operativos_carrera" :key="index">
                <td>  <InputSwitch v-model="item.activo"  @click="changeActive(item)"/> </td>
             
                <!-- <td>{{ item.periodoTipo.periodo ==='Anual'?'':`${item.periodoTipo.periodo} -` }}{{ item.gestionTipoId }}</td> -->
                 <td>{{ item.eventoTipo.evento }}</td>
                 <td>{{ item.modalidadEvaluacionTipo?.modalidadEvaluacion }}</td>
                 <!-- <td>{{  $filters.dateFormat(item.fechaInicio ) }}  </td>
                 <td>{{  $filters.dateFormat(item.fechaFin)  }}</td> -->
                 <td>{{  item.fechaInicio? new Date(`${item.fechaInicio}T00:00:00`).toLocaleDateString('es-pa') : '' }}  </td>
                 <td>{{  item.fechaFin? new Date(`${item.fechaFin}T00:00:00`).toLocaleDateString('es-pa') : ''  }}</td>
                 <td> 
                    <div class="kicon-button-primary" @click="editEvent(item)">
                        <i class='bx bxs-pencil'></i>
                    </div>
                </td>
                 <!-- <td>{{ item.activo?'Activo':'Inactivo' }}</td> -->
             </tr>
         </tbody>
        </table>
        <div v-else class="w-full flex justify-content-center align-items-center">
            <Message severity="info" class="w-full">Debe tener un periodo activo </Message>
        </div>
    </div>
    

    <Dialog v-model:visible="dialog" modal :header="title" :style="{ width: '80vw' }">
        <h2 class="text-center text-xl font-bold mb-4">REGISTRO DE PARALELO - DOCENTE</h2>
        <div class="formgrid grid">

            <div class="field col-12 md:col-6">
                <label >Nombre Paralelo</label>
                <InputText type="text" class="p-inputtext-sm w-full" v-model="registro.nombre_paralelo" placeholder="Introduzca el nombre del paralelo"/>
            </div>

            <div class="field col-12 md:col-6">
                    <label>Turno Paralelo</label>
                    <Dropdown v-model="registro.turno_paralelo"  filter :options="turnos" optionLabel="turno" class=" p-inputtext-sm w-full " placeholder="Seleccionar el turno" />
            </div>  
            
            <div class="field col-12 md:col-6">
                <label >Total clases definidas para el curso</label>
                <InputText type="number" class="p-inputtext-sm w-full" v-model="registro.total_clases" min="0" placeholder="Introduzca el valor requerido" />
            </div>

            <div class="field col-12 md:col-6">
                <label >Total horas</label>
                <InputText type="number" class="p-inputtext-sm w-full" v-model="registro.total_horas" placeholder="Introduzca el total de horas"/>
            </div>
            <div class="field col-12 md:col-6">
                <label>Docente de curso</label>
                <div class="flex align-items-center">
                <Dropdown v-model="registro.docente_curso" filter :options="docentes" optionLabel="nombre_completo" class="p-inputtext-sm w-full mr-2" placeholder="Seleccionar al docente del curso" />
                <Button label="Adicionar" icon="pi pi-plus" @click="adicionarAction" class="p-button-sm" />
                </div>
            </div>
            <DataTable :value="registros" class="w-full">
                <Column field="no" header="NO" />
                <Column field="docente_curso.persona.carnetIdentidad" header="CI" />
                <Column field="docente_curso.nombre_completo" header="NOMBRES" />
                <Column field="docente_curso.vigente" header="ESTADO" />
                <Column field="fechaCambio" header="FECHA DE CAMBIO" />
            </DataTable>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="saveParalelo()" autofocus  :disabled="bunton_disabled" />
        </template>
    </Dialog>
    

</Panel>
</template>
<script>
import CareerDataServie from '@/services/CareerDataServie'
import EventDataService from '@/services/EventDataService'
import GestionDataService from '@/services/GestionDataService'
import OperativeCareerDS from '@/services/OperativeCareerDS'
import AcademicOffertDataService from '@/services/AcademicOffertDataService';
import { helperMixin } from '@/helper'
import InscriptionTeacherService from '@/services/InscriptionTeacherService';
import FormacionSuperiorArtisticaService from '@/services/FormacionSuperiorArtisticaService'

export default {
    
    mixins:[helperMixin],
    data: () => ({
        registros:[],
        registro:{},
        docentes:[],
        active: 0,
        dialog: false,
        paralell: {},
        gestion: {},
        form: {},
        periodo_academico: {},
        periodos: [],
        periodos_ademicos:[],
        operativos_carrera:[],
        events:[],
        modalities: [],
        has_modality: false,
        gestions: [],
        gestion_active: {},
        periodo_active: {},
        turnos:[],
        has_exeptional_recovery: false
     
    }),
    async mounted() {
        this.getTurnos();
        this.getCursoFormacionArtistica();
       await Promise.all([
           await this.getOperativeActive(),
            this.getPeriodosAcademicos(),
            this.getGestions(),
        ])
    },
    computed:{
        
    },
    methods: 
    {
        saveParalelo(){
            console.log(this.registros);
            FormacionSuperiorArtisticaService.storeParalelos(this.registros)
            .then((response)=>{
                console.log('response.data=======>>',response.data);
                this.dialog = false;
            })
        },
        adicionarAction(){
            console.log('this.registro =>',this.registro);
            this.registro.curso_id = parseInt(this.$route.params.id);
            this.registro.total_clases = parseInt(this.registro.total_clases);
            this.registro.total_horas = parseInt(this.registro.total_horas);
            this.registro.turno_id = this.registro.turno_paralelo.id;
            this.registro.estado = true;
            this.registro.docente_id = parseInt(this.registro.docente_curso.id);
            this.registros.push(this.registro);
            console.log('this.registros => ',this.registros);
        },
        getCursoFormacionArtistica(){
            console.log('entra al curso',this.$route.params.id);
            FormacionSuperiorArtisticaService.getById(this.$route.params.id)
            .then((response)=>{
                //this.institution = response.data
                console.log('response.data=======>>',response.data);
                this.course = response.data.data;

                this.getTeachers(this.course.institucion_educativa_id);
            })
        },
        getTeachers(id){
            InscriptionTeacherService.getTeacherInstitution(id).then((response) => {
        console.log('teacher list',response.data)
        this.docentes = response.data;
        for (let index = 0; index < this.docentes.length; index++) {
            this.docentes[index].nombre_completo = this.docentes[index].persona.nombre+' '+this.docentes[index].persona.paterno+' '+this.docentes[index].persona.materno;
        }
        // records.value = data.data.data;
        });
        },
        createParallel()
        {
            this.dialog = true
            this.paralell = {},
            this.type = 'nuevo';
        },
        async getTurnos()
        {
            let response = await AcademicOffertDataService.getTurnos()
            console.log('resp',response.data)
            this.turnos = response.data;
        },

        async generateExecptionalOperative()
        {
            let payload = {
                carrera_autorizada_id: this.$route.params.id,
                gestion_tipo_id: this.gestion_active.id,
                periodo_tipo_id: this.periodo_active.id
            }
            // console.log('payload',payload)
            let response = await CareerDataServie.generateExcepcionalPeriod(payload)
            console.log(response.data)
            await Promise.all([
                                await this.getOperativeActive(),
                                    this.getCareer(),
                                    this.getGestion(),
                                    this.getPeriodosAcademicos(),
                                    this.getEvents(),
                                    this.getGestions(),
                                    
                                ])

        },
    
        async getOperativeActive(){
            let response = await OperativeCareerDS.getOperativeActive(this.$route.params.id)   
            // console.log('operativo activo',response.data)
            this.periodo_active = response.data.periodoTipo
            this.gestion_active = response.data.gestionTipo
            this.updateData()
            this.loadOperativos()
            
               
        },
        getCareer() {
            CareerDataServie.get(this.$route.params.id)
                .then((response) => {
                    this.career = response.data.data
                    console.log(response.data.data)
                     this.getPeriodos()

                     this.getEvaluationModalities(this.career.intervalo_gestion_tipo_id)
                    // console.log(response)
                })
        },
        getGestions(){
           
            GestionDataService.getAll()
                .then( (response) =>{
                    // console.log(response.data)
                    this.gestions = response.data
                    // console.log('gestion lista',response.data)
                    // this.gestions.forEach( item => {
                    //     if(item.vigente)
                    //     {
                    //         this.gestion_active = item 
                    //         // this.loadOperativos()
                    //         // this.updateData()
                    //     }
                    // });
                })
        },
        getEvents()
        {
            EventDataService.getAll()
                .then((response)=>{
                    this.events = response.data
                })
        },

        getEvaluationModalities(id)
        {
            CareerDataServie.getEvaluationModalities(id)
                .then( (response) =>{
                    this.modalities = response.data

                    
                } )
        },

        loadOperativos()
        {
            if(this.gestion_active && this.periodo_active)
            {
                OperativeCareerDS.getOperativos(this.$route.params.id,this.gestion_active.id,this.periodo_active.id)
                    .then((response)=>{
                        this.has_exeptional_recovery = false
                        this.operativos_carrera = this.orderCalendar(response.data) 
                        // console.log('load operativos',this.operativos_carrera)
                    })
            }
            else {

                this.$toast.add({ severity: 'info', summary: 'Seleccione Periodo y Gestion', detail: ' Debe seleccionar un periodo y una gestión al cual se adicionaran los periodos academicos', life: 5000 })
                this.operativos_carrera = []
            }

            this.updateData()
        },

        updateData(){
            // console.log('send gestion ')
            this.$emit('updateData',{ gestion: this.gestion_active, periodo: this.periodo_active})
        },

        getGestion(){
            CareerDataServie.getGestion()
                .then((response)=>{
                    this.gestion = response.data
                    // console.log('gestion lista',response.data)
                })
        },
        getPeriodos()
        {
            CareerDataServie.getPeriodoTipo(this.career.intervalo_gestion_tipo_id)
                .then((response)=> {
                    this.periodos = response.data
                    // console.log('periodos',response.data)
                })
        },
        getPeriodosAcademicos()
        {
            CareerDataServie.getPeriodosAcademicos(this.$route.params.id)
                .then((response)=>{
                    this.periodos_ademicos = response.data.data
                })
        },
        newPeridoAcademico(){
            this.dialog =true
            this.periodo_academico = {}
        },
        savePeriodoAcademico() {
         
            let modalidad_id = 0
            if(this.periodo_academico.evento.id !== 1)
            {
                if(this.periodo_academico.modalidad){
                    modalidad_id = this.periodo_academico.modalidad.id
                }
            }

            if(this.periodo_academico.is_edit)
            {
                
                let payload= 
                {
                
                    fecha_inicio:this.periodo_academico.fecha_inicio.toLocaleDateString('en-CA'),
                    fecha_fin:this.periodo_academico.fecha_fin.toLocaleDateString('en-CA'),
                    modalidad_evaluacion_tipo_id: parseInt(modalidad_id) 
                }
                CareerDataServie.editPeriodo(payload,this.periodo_academico.id)
                    .then(async (response)=>{
                        console.log('response edit',response.data)
                        await Promise.all([
                                        await this.getOperativeActive(),
                                            this.getCareer(),
                                            this.getGestion(),
                                            this.getPeriodosAcademicos(),
                                            this.getEvents(),
                                            this.getGestions(),
                                            
                                        ])
                        // this.getPeriodosAcademicos()
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Se edito el Registro', life: 3000 })
                    }) 
                this.dialog = false 

            }
            else{
                let payload= {
                    carrera_autorizada_id: parseInt(this.career.carrera_autorizada_id),
                    gestion_tipo_id: parseInt(this.gestion.gestion),
                    periodo_tipo_id: parseInt(this.periodo_academico.periodo.id),
                    fecha_inicio:this.periodo_academico.fecha_inicio,
                    fecha_fin:this.periodo_academico.fecha_fin,
                    observacion: this.periodo_academico.observacion,
                    evento_tipo_id: parseInt(this.periodo_academico.evento.id) ,
                    modalidad_evaluacion_tipo_id: parseInt(modalidad_id) 
    
                }
                console.log('payload calendario academico',payload)
                // operativo-carrera-autorizada/carrera/17
                CareerDataServie.createPeriodo(payload)
                    .then(async(response)=>{
                       console.log('response create',response)
                        await Promise.all([
                                        await this.getOperativeActive(),
                                            this.getCareer(),
                                            this.getGestion(),
                                            this.getPeriodosAcademicos(),
                                            this.getEvents(),
                                            this.getGestions(),
                                            
                                        ])
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                    }) 
                this.dialog = false 

            }
            // console.log(payload)
        },
        checkEvent(item)
        {
            console.log('verificadno enveto ',item)
            
            if(item)
            {
                if(item?.evento?.id > 1)
                {
                    this.has_modality = true
                }else{
                    this.has_modality = false
                }
            }

        },
        changeActive(item)
        {
            // console.log(item)
            this.$confirm.require({
                message: 'Cambiar el periodo tendra como resultado inactivar los otros de la lista, esta de acuerdo?',
                header: 'Activar Periodo',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    // console.log('durmiendo')
                    let response = await CareerDataServie.changeStateOperative(item.id)
                    console.log(response)


                    // console.log('despierta ')
                    // this.getPeriodosAcademicos()
                    this.loadOperativos()
                    this.$toast.add({ severity: 'success', summary: 'Cambio Completado', detail: 'Se cambio de estado ', life: 3000 });
                },
                reject: () => {
                    // this.getPeriodosAcademicos()
                    this.loadOperativos()
                    this.$toast.add({ severity: 'info', summary: 'Operacion Cancelada', detail: 'se cancelo la operacion', life: 3000 });
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },
        editEvent(event){
            // console.log('evento ------>',event)
            // let item = Object.assign({},event) 
            let item = {} 
            item.fecha_fin = event.fechaFin ? new Date(event.fechaFin+'T00:00:00')  :  new Date()
            item.fecha_inicio = event.fechaInicio ? new Date(event.fechaInicio+'T00:00:00') : new Date() 
            let periodo = this.periodos.filter((o)=>{
                return o.id  ===  event.periodoTipoId
            })
            // console.log('periodo encontrado' ,periodo)
            periodo.forEach(element => {
                item.periodo = element
            });

            let evento = this.events.filter((o)=>{
                return o.id === event.eventoTipoId
            })
            // console.log('evento',this.events)
            evento.forEach(element => {
                item.evento = element
            });

            let modalidad = this.modalities.filter((o)=>{
                return o.id === event.modalidadEvaluacionTipoId
            })

            modalidad.forEach(element => {
                item.modalidad = element
            });

            item.observacion = event.observacion
            item.id = event.id
            item.is_edit = true
            item.gestion = event?.gestionTipoId
            // item.periodo = periodo
            this.periodo_academico = item
            this.checkEvent(this.periodo_academico)
            
            this.dialog = true
        },
        orderCalendar(list)
        {
            return list.sort( (a,b)=>{
                if(a.modalidadEvaluacionTipoId === 10)
                {
                    this.has_exeptional_recovery = true
                }
                if(a.modalidadEvaluacionTipoId < b.modalidadEvaluacionTipoId )
                {
                    return -1
                }
                
                if(a.modalidadEvaluacionTipoId > b.modalidadEvaluacionTipoId )
                {
                    return 1
                }

                return 0 
            })
        }
        

    }

}
</script>