import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import StudentView from "../views/StudentView.vue";
import LoginView from "../views/Authentication/LoginView.vue";
import InstitutionListView from "../views/Institutions/ListView.vue";
import EditInstitutionView from "../views/Institutions/EditView";
import ShowInstitutionView from "../views/Institutions/ShowView";
import ArtisticListView from "../views/Artistic/ListView.vue";
import EditArtisticView from "../views/Artistic/EditView";
import ShowArtisticView from "../views/Artistic/ShowView";
import StudyPlanView from "../views/StudyPlan/CreateView";
import AcademicOffertView from "../views/AcademicOffert/EditView.vue";
import AcademicOffertShowView from "@/views/AcademicOffert/ShowView.vue";
import AcademicOffertShowTeacherView from "@/views/AcademicOffert/ShowTeachers";
import ClassroomView from "@/views/Classroom/ShowView";
import ClassroomArtisticaView from "@/views/Classroom/ShowViewArtistic";
import ListView from "../views/Career/ListView";
import CareerShowView from "../views/Career/ShowView";
import CourseListView from "../views/Course/ListView.vue";
import CourseShowView from "../views/Course/ShowView";
import FormPerson from "../components/FormPerson.vue";
import FormPersonReference from "../components/FormPersonReference.vue";
import FormTeacher from "../components/FormTeacher.vue";
import FormTeacherJob from "../components/FormTeacherJob.vue";
import CreateNotes from "../views/Teacher/CreateNotes.vue";
import FormPersonArtistica from "@/components/FormPersonArtistica.vue";

// COMPONENTES

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/student",
    name: "student",
    component: StudentView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ListView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/institutions",
    name: "institutions",
    component: InstitutionListView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/institution/edit/:id/:institution_id",
    name: "institution_edit",
    component: EditInstitutionView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/institution/:id",
    name: "institution_show",
    component: ShowInstitutionView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/artistic",
    name: "artistic",
    component: ArtisticListView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/artistic/edit/:id/:artistic_id",
    name: "artistic_edit",
    component: EditArtisticView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/artistic/:id",
    name: "artistic_show",
    component: ShowArtisticView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/artistic/:id",
    name: "course-show",
    component: CourseListView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/study_plan/:plan_estudio_carrera_id/:career_id",
    name: "create_study_plan",
    component: StudyPlanView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/career/list",
    name: "career-list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Career/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/career/:id",
    name: "career_show",
    component: CareerShowView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/course/list",
    name: "course-list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Course/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/course/:id",
    name: "course_show",
    component: CourseShowView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/area/list",
    name: "area-list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Area/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/subject/list",
    name: "subject-list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Subject/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/inscription-teacher/list",
    name: "inscription-teacher-list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/InscriptionTeacher/ListView.vue"
      ),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/inscription-teacher/list/form/teacher/info",
        component: FormTeacher,
      },
      {
        path: "/inscription-teacher/list/form/teacher/job",
        component: FormTeacherJob,
      },
    ],
  },
  {
    path: "/inscription-student/new",
    name: "inscription-student/new",
    component: () => import("../views/InscriptionStudent/New/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/academic_offert/:plan_estudio_carrera_id/:career_id",
    name: "academic_offert",
    component: AcademicOffertView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/registration-student/",
    name: "registration-student",
    component: () => import("../views/RegistrationStudent/ListView.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/registration-student/form/person/info",
        component: FormPerson,
      },
      {
        path: "/registration-student/form/person/reference",
        component: FormPersonReference,
      },
    ],
  },
  {
    path: "/academic_offert_show/:plan_estudio_carrera_id/:career_id",
    name: "academic_offert_show",
    component: AcademicOffertShowView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/academic_offert_show_teacher/:plan_estudio_carrera_id/:career_id",
    name: "academic_offert_show_teacher",
    component: AcademicOffertShowTeacherView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/classroom/:aula_id/:career_id",
    name: "classroom_artistica_show",
    component: ClassroomView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/classroomArtistica/:id/:paralelo_id",
    name: "classroomArtistica",
    component: ClassroomArtisticaView,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/classroomArtistica/form/person/infoArtistica",
        component: FormPersonArtistica,
      },
    ]
  },
/*
  {
    path: "/registration-student/",
    name: "registration-student",
    component: () => import("../views/RegistrationStudent/ListView.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/registration-student/form/person/info",
        component: FormPerson,
      },
      {
        path: "/registration-student/form/person/reference",
        component: FormPersonReference,
      },
    ],
  },

*/


  {
    path: "/evaluation/score",
    name: "evaluation-score",
    component: () => import("../views/EvaluationScore/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/teacher/notes",
    name: "teacher_notes",
    component: CreateNotes,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/inscription-student/regular",
    name: "inscription-student/regular",
    component: () => import("../views/InscriptionStudent/Regular/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/inscription-student/old",
    name: "inscription-student/old",
    component: () => import("../views/InscriptionStudent/Old/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/inscription-student/homologation",
    name: "inscription-student/homologation",
    component: () =>
      import("../views/InscriptionStudent/OldHomologation/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/inscription-student/transitabilidad",
    name: "inscription-student/transitabilidad",
    component: () =>
      import("../views/InscriptionStudent/Transitabilidad/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard/ShowView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/dashboard/institution",
    name: "dashboard_insitituon",
    component: () => import("../views/Dashboard/InsititutionReportView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/dashboard/student",
    name: "dashboard_student",
    component: () => import("../views/Dashboard/StudentReportView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/dashboard/career",
    name: "dashboard_career",
    component: () => import("../views/Dashboard/CareerReportView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/dashboard/teacher",
    name: "dashboard_teacher",
    component: () => import("../views/Dashboard/TeacherReportView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/institution-certificate/:id",
    name: "instition_certificate",
    component: () => import("../views/Institutions/Certificate.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/institution-stadistic/:id",
    name: "instition_stadistic",
    component: () => import("../views/Institutions/InstitutionPdf.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/artistic-certificate/:id",
    name: "artistic_certificate",
    component: () => import("../views/Artistic/Certificate.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/artistic-stadistic/:id",
    name: "artistic_stadistic",
    component: () => import("../views/Artistic/InstitutionPdf.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/report",
    name: "report",
    component: () => import("../views/Report/Index.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/report_degestla",
    name: "report_degestla",
    component: () => import("../views/Report/Degestla.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../views/Report/History.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/inscription-student/info",
    name: "inscription-student/info",
    component: () => import("../views/InscriptionStudent/Info/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/study-plan/list",
    name: "study_plan_list",
    component: () => import("../views/StudyPlan/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/User/ListView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/received",
    name: "received",
    component: () => import("../views/SubDirection/RecievedView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/approved",
    name: "approved",
    component: () => import("../views/SubDirection/ApprovedView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/observed",
    name: "observed",
    component: () => import("../views/SubDirection/ObservedView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/check_study_plan/:id_plan_estudio_carrera",
    name: "check_study_plan",
    component: () => import("../views/SubDirection/CheckStudyPlanView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/record_academic/:aula_id/:carrera_autorizada_id/:periodo_tipo_id",
    name: "record_academic",
    component: () => import("../views/Teacher/RecordAcademicPrint.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/institution-teacher/:codigo_ritt/:carrera_tipo_id",
    name: "institution-teacher",
    component: () => import("../views/Institutions/TeachersView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/artistic-teacher/:codigo_ritt/:carrera_tipo_id",
    name: "artistic-teacher",
    component: () => import("../views/Artistic/TeachersView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/teacher-detail/:carnet_identidad/:codigo_ritt",
    name: "teacher-detail",
    component: () => import("../views/Teacher/ShowDetail.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/passability/received",
    name: "passability/received",
    component: () => import("../views/Passability/RecievedView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/passability/approved",
    name: "passabilityapproved",
    component: () => import("../views/Passability/ApprovedView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/passability/observed",
    name: "passability/observed",
    component: () => import("../views/Passability/ObservedView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/passability/check_transitabilty/:matricula_estudiante_id",
    name: "passability/check_transitabilty",
    component: () => import("../views/Passability/CheckStudyPlanView.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/note-centralizer/:instituto_plan_estudio_carrera_id/:paralelo_tipo_id/:turno_tipo_id/:gestion_tipo_id/:periodo_tipo_id/:regimen_grado_tipo_id",
    name: "note-centralizer",
    component: () => import("../views/Report/NoteCentratilzer.vue"),
    meta: {
      requireAuth: false,
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
