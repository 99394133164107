<template>

<Toast position="bottom-right" />
    <Card>
        <template #title >
          <div class="flex justify-content-between ">
            <div class="flex">
              <span>Lista de Institutos Artística</span>
                
            </div>
            <div class="flex">
              
              <span class="p-input-icon-left pr-2">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
              <Button label="Nuevo Instituto" severity="success" icon="pi pi-plus"  @click="$router.push('institution/edit/0/0')" />                
              <div class="pr-1"></div>
              <Button label="Migrar Instituto" severity="secondary" icon="pi pi-sync"  @click="openDialog" />             
            </div>
          </div> 
        </template>
        <template #subtitle>
        </template>
        <template #content>
            <DataTable :value="institutions" paginator :rows="rows_numbers" :rowsPerPageOptions="[5,10,15, 30, 50]" v-model:filters="filters" 
            tableStyle="min-width: 50rem" :loading="loading" >
              
                <template #empty> No se encontraron institutos. </template>
                <template #loading> Loading customers data. Please wait. </template>
                <Column style="width: 10%" > 
                    <!-- <template #body="{ data,index }"> -->
                      <template #body="{data}" >
                        <!-- <button type="button" class="btn btn-primary"> </button> -->

                       
                        <div class="btn-group me-2" role="group" aria-label="First group">
                            <Button v-tooltip="'Ver Detalle'" icon="mdi mdi-eye" text raised rounded aria-label="Filter" @click="redirectInstitution(data.ie_id)" />
                        </div>
                        <div class="btn-group me-2" role="group" aria-label="First group">
                            <Button v-tooltip="'Ver Docentes'" icon="bx bxs-group" text raised rounded aria-label="Filter" @click="redirectInstitutionTeachers(data.ie_id)" />
                        </div>
                        <div class="btn-group me-2" role="group" aria-label="First group" v-if="data.sede_sebsede=='SEDE' || data.sede_sebsede=='0' ">
                        
                          <Button v-tooltip="'Crear Sucursal'" icon="mdi mdi-plus" severity="success"  text raised rounded aria-label="Filter"  @click="$router.push(`institution/edit/0/${data.ie_id}`)" />
                        </div>
                        <!-- <div class="btn-group me-2" role="group" aria-label="First group" v-if="data.sede_sebsede=='SEDE' || data.sede_sebsede=='0' ">
                        
                          <Button v-tooltip="'Crear Sucursal'" icon="mdi mdi-plus" severity="success"  text raised rounded aria-label="Filter"  @click="$router.push(`institution/edit/0/${data.ie_id}`)" />
                        </div> -->
                        
                    </template>
                </Column>
                <Column field="institucion_educativa" header="NOMBRE INSTITUTO" style="width: 30%" sortable >
                <!-- <template #body="{ data }">
                    {{ data.name }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by name" />
                </template> -->
              </Column>
              <Column field="ie_id" header="CÓDIGO RITT" style="width: 10%" sortable></Column>
              <Column field="sede_sebsede" header="SEDE/SUBSEDE" style="width: 10%" sortable>
                <template #body="{ data }">
                  
                  <Tag severity="info" :value="data.sede_sebsede" v-if="data.sede_sebsede=='SUBSEDE'"></Tag>
                  <Tag severity="success" :value="data.sede_sebsede!='0'?'SEDE':'SEDE' " v-else></Tag>
                    
                </template>
              </Column>
              <Column field="departamento" header="DEPTO" style="width: 10%" sortable></Column>
              <Column field="tipo_institucion" header="TIPO INSTITUCIÓN" style="width: 15%" sortable></Column>
              <Column field="numero_resolucion" header="RESOLUCIÓN" style="width: 10%" sortable> </Column>
              <Column field="caracter_juridico" header="CARÁCTER JURÍDICO" style="width: 10%"></Column>
              <Column field="cod_le" header="COD.LE" style="width: 10%" sortable></Column>
             
              <template #paginatorstart>
                
              </template>
              <template #paginatorend>
                <div class="flex align-items-center ">
                  <a :href="urlxls" class="klink-icon"  v-tooltip.left="'Lista Excel'">
                      <i class="pi pi-file-excel"></i>
                  </a> 
                  <strong> Total Institutos {{ institutions.length }}</strong>
                </div>
              </template>
          </DataTable>
        </template>
    </Card>
    
    <Dialog v-model:visible="dialog" modal :header="`Migrar Instituto del SIE RITT `" >
          <div class="flex flex-column">
              <label for="username" class="font-semibold">Codigo RITT: </label>
              <InputNumber inputId="withoutgrouping" :useGrouping="false"  class="flex-auto"  v-model="ritt_code"/>
              
          </div>
          <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
                    <Button label="Migrar" icon="pi pi-download"  @click="migrate" />
          </template>
    </Dialog>
</template>
<script setup >
import {ref,onMounted, computed} from 'vue'
import { FilterMatchMode } from 'primevue/api';
import InstitutionDataService from '@/services/InstitutionDataService';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { useToast } from 'primevue/usetoast';

const router = useRouter(); 
const store = useStore()
const toast = useToast();
const url = ref()
const urlxls = ref()
const institutions = ref([])
const filters = ref({
                  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  institucion_educativa: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  ie_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  departamento: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  tipo_institucion: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  numero_resolucion: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  caracter_juridico: { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
const loading = ref(false)
const rows_numbers = computed(()=>{
    return store.state.rows_number
})
const dialog = ref(false)
const ritt_code = ref(null)

const openDialog = () =>{
  dialog.value = true 
  ritt_code.value = null
}

const migrate = async ()=>{
  try {

    let response = await InstitutionDataService.migrate(ritt_code.value)
      console.log('response =======:>', response)
      // if(response.data.statusCode === 404)
      // {
      //   toast.add({severity:'info', summary: 'Info', detail: `No se encontro el Instituto o ya se encuentra registrado`, life: 3000});
      // }

      // if(response.data.statusCode === 200)
      // {
        toast.add({severity:'success', summary: 'success', detail: `Se Registro al Instituto`, life: 3000});
      // }
    
  } catch (error) {
        toast.add({severity:'info', summary: 'Info', detail: `No se encontro el Instituto o ya se encuentra registrado`, life: 3000});
  }  
  getInstitutions()
  dialog.value = false
}

const getInstitutions =  async () =>{
  let response = await  InstitutionDataService.getArtistic()
  institutions.value = response.data
  // let lapaz = 0
  // let cochabamba = 0
  // let chuquisaca = 0
  // let oruro  = 0
  // let potosi = 0
  // let tarija = 0
  // let santacruz = 0
  // let beni = 0
  // let pando = 0


  // await Promise.all(
  //   response.data.map(async (institution) => {
      
  //     if(institution.sede_sebsede === "SEDE"  )
  //     {
  //       let result = await InscriptionTeacherService.getAllBySieYear(institution.ie_id, 2023, 53)
  //       console.log(institution)
  //       console.log(result.data.data)
  //         switch (institution.departamento) {
  //           case 'La Paz':

  //               if(result.data.data.length > 1)
  //               {
  //                 lapaz++
  //               }
  //               break;
            
  //           case 'Cochabamba':

  //           if(result.data.data.length > 1)
  //           {
  //             cochabamba++
  //           }
  //           break;

  //           case 'Chuquisaca':

  //             if(result.data.data.length > 1)
  //             {
  //               chuquisaca++
  //             }
  //             break;
            

  //             case 'Oruro':

  //             if(result.data.data.length > 1)
  //             {
  //               oruro++
  //             }
  //             break;

  //             case 'Potosi':

  //             if(result.data.data.length > 1)
  //             {
  //               potosi++
  //             }
  //             break;


  //           case 'Tarija':

  //           if(result.data.data.length > 1)
  //           {
  //             tarija++
  //           }
  //           break;

  //           case 'Santa Cruz':

  //           if(result.data.data.length > 1)
  //           {
  //             santacruz++
  //           }
  //           break;

  //           case 'Beni':

  //           if(result.data.data.length > 1)
  //           {
  //             beni++
  //           }
  //           break;

  //           case 'Pando':

  //           if(result.data.data.length > 1)
  //           {
  //             pando++
  //           }
  //           break;
          
  //         }
  //     }

  //   })
  // )
  
  // console.log('la paz', lapaz )
  // console.log('cochabamba',cochabamba)  
  // console.log('chuquisaca',chuquisaca)  
  // console.log('oruro',oruro)   
  // console.log('potosi',potosi)  
  // console.log('tarija',tarija)  
  // console.log('santacruz',santacruz)  
  // console.log('beni',beni)  
  // console.log('pando',pando)  


  

}

const redirectInstitution = (id)=>{
  router.push(`/artistic/${id}`)
}

const redirectInstitutionTeachers = (id)=>{

  console.log('send rit',id)
  store.dispatch('saveInstitutionId',id)
  router.push(`/inscription-teacher/list`)
}

onMounted(()=>{
  getInstitutions()
  url.value = process.env.VUE_APP_URL,        
  urlxls.value = `${url.value}/institucion-educativa-sucursal/xlsitts` 
})



</script>