<template>
  <Toast position="bottom-right" />
  <ConfirmDialog></ConfirmDialog>
  <div>
    <!-- ============================================================== -->
    <!-- Preloader - style you can find in spinners.css -->
    <!-- ============================================================== -->
    <!-- <div class="preloader">
      <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
      </div>
    </div> -->
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      :data-sidebartype="closeSide ? 'full' : 'mini-sidebar'"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      :data-boxed-layout="closeSide ? 'full' : 'mini-sidebar'"
      v-if="$route.meta.requireAuth"
    >
      <!-- ============================================================== -->
      <!-- Topbar header - style you can find in pages.scss -->
      <!-- ============================================================== -->
      <header class="topbar" data-navbarbg="skin6">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          <div class="navbar-header" data-logobg="skin6">
            <!-- ============================================================== -->
            <!-- Logo -->
            <!-- ============================================================== -->
            <a class="navbar-brand" href="#">
              <!-- Logo icon -->
              <b class="logo-icon">
                <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                <!-- Dark Logo icon -->
                <img
                  src="/logo2.png"
                  alt="homepage"
                  class="dark-logo"
                  width="50"
                  height="50"
                />
              </b>
              <!--End Logo icon -->
              <!-- Logo text -->
              <span
                class="logo-text text-dark text-xl font-semibold text-color-secondary"
              >
                <!-- dark Logo text -->
                <!-- <img
                  src="../assets/images/logo-text.png"
                  alt="homepage"
                  class="dark-logo"
                  
                /> -->
                <span class="pl-2">ITT Académico </span
                ><small class="text-400">v2.3</small>
              </span>
            </a>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <!-- <a class="
                nav-toggler
                waves-effect waves-light
                text-dark
                d-block d-md-none
              " href=""><i class='bx bxs-left-arrow-circle'></i></a> -->
          </div>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <div
            class="navbar-collapse collapse"
            id="navbarSupportedContent"
            data-navbarbg="skin5"
          >
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav me-auto mt-md-0">
              <!-- ============================================================== -->
              <!-- Search -->
              <!-- ============================================================== -->
              <li class="nav-item">
                <a
                  class="flex align-items-center justify-content-center text-white text-xl pl-2 pt-2"
                  @click="closeSide = !closeSide"
                >
                  <i class="bx bx-menu"></i>
                </a>
              </li>
              <li class="nav-item hidden-sm-down">
                <div
                  class="flex align-items-center text-white text-xl"
                  v-if="role && role.rol === 'DIRECTOR'"
                >
                  <div class="pl-3">
                    <i class="duotone icon-home" style="font-size: 2rem"></i>
                  </div>
                  <strong class="pl-2">{{ institution?.ie_nombre }}</strong>
                </div>

                <div
                  class="flex align-items-center text-white text-xl"
                  v-if="role && role.rol === 'DOCENTE - CATEDRÁTICO'"
                >
                  <div class="pl-3">
                    <i class="duotone icon-home" style="font-size: 2rem"></i>
                  </div>
                  <strong class="pl-2">{{ institution?.ie_nombre }}</strong>
                </div>

                <div
                  class="flex align-items-center text-white text-xl"
                  v-if="role && role.rol === 'DGESTTLA'"
                >
                  <div class="pl-3">
                    <i class="duotone icon-home" style="font-size: 2rem"></i>
                  </div>
                  <strong class="pl-2">{{ institution?.ie_nombre }}</strong>
                </div>

                <!-- <form class="app-search ps-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search for..."
                  />
                  <a class="srh-btn"
                    ><i class="mdi mdi-magnify fs-4 lh-sm"></i
                  ></a>
                </form> -->
              </li>
            </ul>

            <!-- ============================================================== -->
            <!-- Right side toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav">
              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
              <li class="nav-item dropdown">
                <a
                  class="nav-link kdropdown"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown "
                  aria-expanded="false"
                  v-tooltip.left="'Rol Actual'"
                >
                  <!-- <img
                    src="../assets/images/users/1.jpg"
                    alt="user"
                    class="profile-pic me-2"
                  /> -->
                  <!-- {{ JSON.stringify(user) }} -->
                  <div
                    class="flex align-items-center animate__animated animate__bounce"
                  >
                    <i
                      class="bx bxs-user-rectangle"
                      style="font-size: 2rem"
                    ></i>
                    <strong class="pl-2"> {{ role ? role.rol : "" }} </strong>
                  </div>
                </a>
                <div class="kdropdown-content animate__fadeOut">
                  <a class="flex align-items-center" href="#">
                    <i
                      class="bx bx-user text-info"
                      style="font-size: 1.8rem"
                    ></i>
                    <span class="pl-3">Mi Perfil</span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a
                    class="flex align-items-center"
                    href="#"
                    @click="openDialog()"
                  >
                    <i
                      class="bx bx-refresh text-success"
                      style="font-size: 1.8rem"
                    ></i>
                    <span class="pl-3">Cambiar Rol</span>
                  </a>
                  <a
                    class="flex align-items-center"
                    href="#"
                    @click="openDialogUser()"
                  >
                    <i
                      class="bx bxs-lock-alt text-success"
                      style="font-size: 1.8rem"
                    ></i>
                    <span class="pl-3">Cambiar Password</span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="flex align-items-center" href="" @click="logOut">
                    <i
                      class="bx bx-log-out text-danger"
                      style="font-size: 1.8rem"
                    ></i>
                    <span class="pl-3"> Cerrar Sesion</span>
                  </a>
                </div>
                <!-- <div :class="`dropdown-menu dropdown-menu-end user-dd animated flipInY ${menu_profile?'show':''}`">
                  <div class="d-flex no-block align-items-center p-3 bg-info text-white mb-2">
                    <div class="">
                      <img src="" alt="user" class="rounded-circle" width="60">
                    </div>
                    <div class="ms-2">
                      <h4 class="mb-0 text-white">Marken Doe</h4>
                      <p class="mb-0">deo@gmail.com</p>
                    </div>
                  </div>
                  <a class="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user feather-sm text-info me-1 ms-1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    My Profile</a>
                  <a class="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card feather-sm text-info me-1 ms-1"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                    My Balance</a>
                  <a class="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail feather-sm text-success me-1 ms-1"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                    Inbox</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings feather-sm text-warning me-1 ms-1"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                    Account Setting</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out feather-sm text-danger me-1 ms-1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                    Logout</a>
                
                </div> -->
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <!-- ============================================================== -->
      <!-- End Topbar header -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Left Sidebar - style you can find in sidebar.scss  -->
      <!-- ============================================================== -->
      <aside class="left-sidebar" data-sidebarbg="skin6">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar">
          <!-- Sidebar navigation-->
          <nav class="sidebar-nav">
            <ul id="sidebarnav">
              <!-- User Profile-->

              <li>
                <!-- <div class="kuser-profile">
                  <img src="avatar_user.png" alt="user" class="kuser-image">
                </div> -->
                <div
                  :class="`user-profile text-center position-relative  mt-1 ${
                    closeSide ? '' : 'profile-minisidebar'
                  }`"
                >
                  <div class="profile-img m-auto">
                    <img
                      src="/avatar_user.png"
                      alt="user"
                      class="w-50 rounded-circle"
                    />
                  </div>

                  <div class="profile-text py-1">
                    <a
                      href="#"
                      class="dropdown-toggle link u-dropdown"
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="true"
                      >{{ currentUser ? currentUser.persona : "" }}
                      <span class="caret"></span>
                    </a>
                    <div class="dropdown-menu">
                      <a class="dropdown-item flex align-items-center" href="#">
                        <i
                          class="bx bx-user text-info"
                          style="font-size: 1.8rem"
                        ></i>
                        <span class="pl-3">Mi Perfil</span>
                      </a>
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item flex align-items-center"
                        href="#"
                        @click="openDialog"
                      >
                        <i
                          class="bx bx-refresh text-success"
                          style="font-size: 1.8rem"
                        ></i>
                        <span class="pl-3">Cambiar Rol</span>
                      </a>
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item flex align-items-center"
                        href=""
                        @click="logOut"
                      >
                        <i
                          class="bx bx-log-out text-danger"
                          style="font-size: 1.8rem"
                        ></i>
                        <span class="pl-3"> Cerrar Sesion</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>

              <div v-if="role">
                <div v-if="role.rol === 'DIRECTOR'">
                  <li
                    class="sidebar-item"
                    v-for="(menu, index) in menu_director"
                    :key="index"
                  >
                    <div v-if="menu.path != '#'">
                      <router-link
                        v-slot="{}"
                        :to="menu.path"
                        :class="`sidebar-link waves-effect waves-dark sidebar-link `"
                        aria-expanded="false"
                      >
                        <i
                          :class="`me-3 ${menu.icon} fs-3`"
                          aria-hidden="true"
                        ></i
                        ><span class="hide-menu"> {{ menu.label }}</span>
                      </router-link>
                    </div>
                    <div v-else>
                      <a
                        :class="`sidebar-link has-arrow waves-effect waves-dark ${
                          menu.sw ? 'active' : ''
                        }`"
                        aria-expanded="false"
                        @click="menu.sw = !menu.sw"
                      >
                        <i :class="menu.icon"></i>
                        <span class="hide-menu">{{ menu.label }}</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        :class="`collapse first-level ${menu.sw ? 'in' : ''}`"
                      >
                        <li
                          class="sidebar-item"
                          v-for="(subitem, index_subitem) in menu.subitems"
                          :key="index_subitem"
                        >
                          <router-link
                            :to="subitem.path"
                            class="sidebar-link waves-effect waves-dark"
                            aria-expanded="false"
                          >
                            <i :class="`me-3 ${subitem.icon} fs-3`"></i>
                            <span class="hide-menu">{{ subitem.label }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>
                <div v-if="role.rol === 'DGESTTLA' || role.rol === 'ADMIN'">
                  <li
                    class="sidebar-item"
                    v-for="(menu, index) in menu_dgestla"
                    :key="index"
                  >
                    <div v-if="menu.path != '#'">
                      <router-link
                        v-slot="{}"
                        :to="menu.path"
                        :class="`sidebar-link waves-effect waves-dark sidebar-link `"
                        aria-expanded="false"
                      >
                        <i
                          :class="`me-3 ${menu.icon} fs-3`"
                          aria-hidden="true"
                        ></i
                        ><span class="hide-menu"> {{ menu.label }}</span>
                      </router-link>
                    </div>
                    <div v-else>
                      <a
                        :class="`sidebar-link has-arrow ${
                          menu.sw ? 'active' : ''
                        }`"
                        aria-expanded="false"
                        @click="menu.sw = !menu.sw"
                      >
                        <i :class="menu.icon"></i>
                        <div class="pr-3"></div>
                        <span class="hide-menu">{{ menu.label }}</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        :class="`collapse first-level ${menu.sw ? 'in' : ''}`"
                      >
                        <li
                          class="sidebar-item"
                          v-for="(subitem, index_subitem) in menu.subitems"
                          :key="index_subitem"
                        >
                          <router-link
                            :to="subitem.path"
                            class="sidebar-link waves-effect waves-dark"
                            aria-expanded="false"
                          >
                            <i :class="`me-3 ${subitem.icon} fs-3`"></i>
                            <span class="hide-menu">{{ subitem.label }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>
                <div v-if="role.rol === 'SUPERADMIN'">
                  <li
                    class="sidebar-item"
                    v-for="(menu, index) in menu_superadmin"
                    :key="index"
                  >
                    <div v-if="menu.path != '#'">
                      <router-link
                        v-slot="{}"
                        :to="menu.path"
                        :class="`sidebar-link waves-effect waves-dark sidebar-link `"
                        aria-expanded="false"
                      >
                        <i
                          :class="`me-3 ${menu.icon} fs-3`"
                          aria-hidden="true"
                        ></i
                        ><span class="hide-menu"> {{ menu.label }}</span>
                      </router-link>
                    </div>
                    <div v-else>
                      <a
                        :class="`sidebar-link has-arrow waves-effect waves-dark ${
                          menu.sw ? 'active' : ''
                        }`"
                        aria-expanded="false"
                        @click="menu.sw = !menu.sw"
                      >
                        <i :class="menu.icon"></i>
                        <span class="hide-menu">{{ menu.label }}</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        :class="`collapse first-level ${menu.sw ? 'in' : ''}`"
                      >
                        <li
                          class="sidebar-item"
                          v-for="(subitem, index_subitem) in menu.subitems"
                          :key="index_subitem"
                        >
                          <router-link
                            :to="subitem.path"
                            class="sidebar-link waves-effect waves-dark"
                            aria-expanded="false"
                          >
                            <i :class="`me-3 ${subitem.icon} fs-3`"></i>
                            <span class="hide-menu">{{ subitem.label }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>

                <div v-if="role.rol === 'DOCENTE - CATEDRÁTICO'">
                  <li
                    class="sidebar-item"
                    v-for="(menu, index) in menu_maestro"
                    :key="index"
                  >
                    <div v-if="menu.path != '#'">
                      <router-link
                        v-slot="{ route }"
                        :to="menu.path"
                        :class="`sidebar-link waves-effect waves-dark sidebar-link `"
                        aria-expanded="false"
                      >
                        <i
                          :class="`me-3 ${menu.icon} fs-3`"
                          aria-hidden="true"
                        ></i
                        ><span class="hide-menu"> {{ menu.label }}</span>
                      </router-link>
                    </div>
                    <div v-else>
                      <a
                        :class="`sidebar-link has-arrow waves-effect waves-dark ${
                          menu.sw ? 'active' : ''
                        }`"
                        aria-expanded="false"
                        @click="menu.sw = !menu.sw"
                      >
                        <i :class="menu.icon"></i>
                        <span class="hide-menu">{{ menu.label }}</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        :class="`collapse first-level ${menu.sw ? 'in' : ''}`"
                      >
                        <li
                          class="sidebar-item"
                          v-for="(subitem, index_subitem) in menu.subitems"
                          :key="index_subitem"
                        >
                          <router-link
                            :to="subitem.path"
                            class="sidebar-link waves-effect waves-dark"
                            aria-expanded="false"
                          >
                            <i :class="`me-3 ${subitem.icon} fs-3`"></i>
                            <span class="hide-menu">{{ subitem.label }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>

                <div v-if="role.rol === 'ESTUDIANTE'">
                  <li
                    class="sidebar-item"
                    v-for="(menu, index) in menu_estudiante"
                    :key="index"
                  >
                    <div v-if="menu.path != '#'">
                      <router-link
                        v-slot="{}"
                        :to="menu.path"
                        :class="`sidebar-link waves-effect waves-dark sidebar-link `"
                        aria-expanded="false"
                      >
                        <i
                          :class="`me-3 ${menu.icon} fs-3`"
                          aria-hidden="true"
                        ></i
                        ><span class="hide-menu"> {{ menu.label }}</span>
                      </router-link>
                    </div>
                    <div v-else>
                      <a
                        :class="`sidebar-link has-arrow waves-effect waves-dark ${
                          menu.sw ? 'active' : ''
                        }`"
                        aria-expanded="false"
                        @click="menu.sw = !menu.sw"
                      >
                        <i :class="menu.icon"></i>
                        <span class="hide-menu">{{ menu.label }}</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        :class="`collapse first-level ${menu.sw ? 'in' : ''}`"
                      >
                        <li
                          class="sidebar-item"
                          v-for="(subitem, index_subitem) in menu.subitems"
                          :key="index_subitem"
                        >
                          <router-link
                            :to="subitem.path"
                            class="sidebar-link waves-effect waves-dark"
                            aria-expanded="false"
                          >
                            <i :class="`me-3 ${subitem.icon} fs-3`"></i>
                            <span class="hide-menu">{{ subitem.label }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>

                <div v-if="role.rol === 'DDE SUBDIRECCION ED.SUPERIOR'">
                  <li
                    class="sidebar-item"
                    v-for="(menu, index) in menu_subdireccion_educacion_superio"
                    :key="index"
                  >
                    <div v-if="menu.path != '#'">
                      <router-link
                        v-slot="{}"
                        :to="menu.path"
                        :class="`sidebar-link waves-effect waves-dark sidebar-link `"
                        aria-expanded="false"
                      >
                        <i
                          :class="`me-3 ${menu.icon} fs-3`"
                          aria-hidden="true"
                        ></i
                        ><span class="hide-menu"> {{ menu.label }}</span>
                      </router-link>
                    </div>
                    <div v-else>
                      <a
                        :class="`sidebar-link has-arrow waves-effect waves-dark ${
                          menu.sw ? 'active' : ''
                        }`"
                        aria-expanded="false"
                        @click="menu.sw = !menu.sw"
                      >
                        <i :class="menu.icon"></i>
                        <span class="hide-menu">{{ menu.label }}</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        :class="`collapse first-level ${menu.sw ? 'in' : ''}`"
                      >
                        <li
                          class="sidebar-item"
                          v-for="(subitem, index_subitem) in menu.subitems"
                          :key="index_subitem"
                        >
                          <router-link
                            :to="subitem.path"
                            class="sidebar-link waves-effect waves-dark"
                            aria-expanded="false"
                          >
                            <i :class="`me-3 ${subitem.icon} fs-3`"></i>
                            <span class="hide-menu">{{ subitem.label }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </nav>
          <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
      </aside>
      <!-- ============================================================== -->
      <!-- End Left Sidebar - style you can find in sidebar.scss  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Page wrapper  -->
      <!-- ============================================================== -->
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
          <div class="row align-items-center">
            <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">{{ getTitle(breadcrumbs) }}</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li :class="!bread.active ? 'breadcrumb-item ' : 'breadcrumb-item active'"
                      v-for="(bread, index) in breadcrumbs" :key="index">
                      <a href="#">{{ bread.name }}</a>
                    </li>
                  
                  </ol>
                </nav>
              </div>
            </div> -->
          </div>
        </div>

        <!-- {{ JSON.stringify(breadcrumbs) }} -->
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- VUE CONTENT HERE -->
          <!-- ============================================================== -->
          <!-- <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
          </nav> -->
          <router-view />
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <!-- <footer class="footer text-center">
          © 2023 Ministerio de Educacion
          <a href="https://www.minedu.gob.bo/">minedu.gob.bo</a>
        </footer> -->
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
    <Dialog
      v-model:visible="dialog"
      modal
      header="Cambiar Rol"
      :style="{ width: '30vw' }"
    >
      <div class="grid" v-if="currentUser">
        <div class="col-12">
          <label for="" class="pr-4"> Instituto: </label><br />
          <Dropdown
            v-model="selectedInstitution"
            :options="currentUser.institutos"
            optionLabel="ie_nombre"
            placeholder="Selecione un Instituto"
            class="w-full md:w-full"
          />
        </div>
        <div class="col-12" v-if="selectedInstitution">
          <label for="" class="pr-4"> Roles asignados: </label> <br />
          <Dropdown
            v-model="selectedRol"
            :options="selectedInstitution.roles"
            optionLabel="rol"
            placeholder="Selecione un Rol"
            class="w-full md:w-14rem"
          />
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          @click="dialog = false"
          text
        />
        <Button
          label="Aceptar"
          icon="pi pi-check"
          @click="changeRol(selectedRol)"
        />
      </template>
    </Dialog>
    <ChangePassword
      :dialog="user_dialog"
      :user="user"
      @closeDialog="closeDialog"
      @resetPassword="resetPassword"
    >
    </ChangePassword>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import AuthService from "./services/AuthService";

import ChangePassword from "@/components/ChangePassword.vue";
import { useToast } from "primevue/usetoast";
const toast = useToast();

const store = useStore();
// const user = ref(JSON.parse(localStorage.getItem('user')))
const router = useRouter();
const dialog = ref(false);

const closeSide = ref(true);

// const role = reactive({})
const selectedRol = ref();
const selectedInstitution = ref();
// const breadcrumbs = computed(() => {
//   return store.state.breadcrumbs
// })
const user = ref();
const user_dialog = ref(false);

const openDialogUser = () => {
  user.value = {
    id: currentUser.value.user_id,
    persona: {
      nombre: currentUser.value.persona,
      materno: "",
      paterno: "",
      carnetIdentidad: currentUser.value.username,
    },
  };
  user_dialog.value = true;
};

const closeDialog = () => {
  // console.log(value)
  user_dialog.value = false;
};

const resetPassword = async (item) => {
  console.log(item);
  if (item.newpass === item.newpass2) {
    let payload = { id: parseInt(item.id), password: item.newpass };
    let response = await AuthService.changePassword(payload);
    toast.add({
      severity: "success",
      summary: "Correcto",
      detail: "Registro actualizado",
      life: 3000,
    });
    closeDialog(false);
    console.log(response.data);
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail:
        "Su nuevo password debe coincidir con la confirmacion del password",
      life: 3000,
    });
  }
};

const menu_estudiante = ref([]);
const menu_director = ref([]);
const menu_maestro = ref([]);
const menu_subdireccion_educacion_superio = ref([]);

const menu_dgestla = ref([
  {
    label: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    path: "/dashboard",
  },
  {
    label: "Trámite Plan de Estudio",
    icon: "pi pi-briefcase",
    path: "#",
    sw: false,
    subitems: [
      {
        label: "Recibidos",
        icon: "bx bxs-inbox",
        path: `/received`,
      },
      {
        label: "Aprobados",
        icon: "bx bxs-file-export",
        path: `/approved`,
      },
      {
        label: "Observados",
        icon: "bx bxs-file-find",
        path: `/observed`,
      },
    ],
  },

  {
    label: "Planes de estudio",
    icon: "pi pi-calendar-plus",
    path: "/study-plan/list",
  },
  {
    label: "Institutos",
    icon: "pi pi-building",
    path: "/institutions",
  },
  {
    label: "Institutos Artística",
    icon: "pi pi-home",
    path: "/artistic",
  },
  {
    label: "Usuarios",
    icon: "pi pi-users",
    path: "/users",
  },
  {
    label: "Paramétricas",
    icon: "pi pi-box",
    path: "#",
    sw: false,
    subitems: [
      {
        label: "Carreras / Cursos",
        icon: "mdi mdi-book-multiple",
        path: "/career/list",
      },
      {
        label: "Asignaturas",
        icon: "mdi mdi-book-multiple",
        path: "/subject/list",
      },
      {
        label: "Áreas",
        icon: "mdi mdi-book-multiple",
        path: "/area/list",
      },
    ],
  },
  {
    label: "Reportes",
    icon: "mdi mdi-view-list",
    path: "/report",
  },
  {
    label: "Reportes Dgesttla",
    icon: "pi pi-file-excel",
    path: "/report_degestla",
  },
  {
    label: "Historial Académico",
    icon: "mdi mdi-view-list",
    path: "/history",
  },
]);

const menu_superadmin = ref([
  {
    label: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    path: "/dashboard",
  },
  {
    label: "Trámite Plan de Estudio",
    icon: "pi pi-briefcase",
    path: "#",
    sw: false,
    subitems: [
      {
        label: "Recibidos",
        icon: "bx bxs-inbox",
        path: `/received`,
      },
      {
        label: "Aprobados",
        icon: "bx bxs-file-export",
        path: `/approved`,
      },
      {
        label: "Observados",
        icon: "bx bxs-file-find",
        path: `/observed`,
      },
    ],
  },

  {
    label: "Planes de estudio",
    icon: "pi pi-calendar-plus",
    path: "/study-plan/list",
  },
  {
    label: "Institutos",
    icon: "pi pi-building",
    path: "/institutions",
  },
  {
    label: "Institutos Artística",
    icon: "pi pi-home",
    path: "/artistic",
  },
  {
    label: "Usuarios",
    icon: "pi pi-users",
    path: "/users",
  },
  {
    label: "Paramétricas",
    icon: "pi pi-box",
    path: "#",
    sw: false,
    subitems: [
      {
        label: "Carreras / Cursos",
        icon: "mdi mdi-book-multiple",
        path: "/career/list",
      },
      {
        label: "Asignaturas",
        icon: "mdi mdi-book-multiple",
        path: "/subject/list",
      },
      {
        label: "Áreas",
        icon: "mdi mdi-book-multiple",
        path: "/area/list",
      },
    ],
  },
  {
    label: "Reportes",
    icon: "mdi mdi-view-list",
    path: "/report",
  },
  {
    label: "Reportes Dgesttla",
    icon: "pi pi-file-excel",
    path: "/report_degestla",
  },
  {
    label: "Historial Académico",
    icon: "mdi mdi-view-list",
    path: "/history",
  },
]);

// window.onclick = () => {
//   menu_profile.value = false
//   // console.log('cllick window')
// }

onMounted(() => {
  if (store.state.auth.status.loggedIn) {
    loadMenuDirector();
    loadMenuMaestro();
    loadMenuEstudiante();
    loadMenuSubdireccion();
  }

  router.beforeEach((to, from, next) => {
    if (to.name !== "login" && !store.state.auth.status.loggedIn)
      next({ name: "login" });
    else next();
  });

  router.afterEach((to) => {
    //  console.log(to.fullPath)
    if (to.fullPath == "/") {
      if (store.state.auth.status.loggedIn) {
        loadMenuDirector();
        loadMenuMaestro();
        loadMenuEstudiante();
        loadMenuSubdireccion();
      }
    }
  });
});

// const getTitle =(list)=> {
//   let title = ''
//   let bread = list.find(o => o.active == false)
//   if (bread) {
//     title = bread.name
//   }

//   return title
// }

const logOut = () => {
  store.dispatch("auth/logout");
  router.push("/login");
};
const openDialog = () => {
  dialog.value = true;
  selectedRol.value = role.value;
};

const changeRol = () => {
  store.dispatch("auth/changeInstitution", selectedInstitution.value);
  store.dispatch("auth/changeRol", selectedRol.value);
  // router.push('/');
  //  role.value = selectedRol.value
  dialog.value = false;
  if (store.state.auth.status.loggedIn) {
    loadMenuDirector();
    loadMenuMaestro();
    loadMenuEstudiante();
    loadMenuSubdireccion();
  }
  // solucion rapida hasta convertir template jquery to vanilla
  // window.location.reload()
  console.log(selectedRol.value);
  if (selectedRol.value.rol === "DGESTTLA") {
    router.push("/dashboard");
  } else {
    router.push("/");
  }
};

const institution = computed(() => {
  return store.state.auth.institution;
});

const currentUser = computed(() => {
  return store.state.auth.user;
});

const role = computed(() => {
  return store.state.auth.rol;
});

const message = computed(() => {
  return store.state.message;
});

const severity = computed(() => {
  return store.state.severity;
});

const title = computed(() => {
  return store.state.title;
});

watch(message, (newMessage) => {
  console.log("severity", severity.value);
  console.log("mensaje entrante", newMessage);
  if (newMessage !== "") {
    toast.add({
      severity: severity.value,
      summary: title.value,
      detail: newMessage,
      life: 3000,
    });
    store.dispatch("showMessage", { severity: "sucess", message: "" });
  }
});

//intested
// const path = computed(()=>{
//   return window.location.pathname
// })

const loadMenuDirector = async () => {
  let institution = await new Promise((resolve) => {
    resolve(store.state.auth.institution);
  });
  console.log(institution.ie_id);
  if (institution) {
    let kmenu = [
      {
        label: "Inicio",
        icon: "mdi mdi-view-dashboard",
        path: `/`,
      },
      {
        label: "Datos Instituto",
        icon: "bx bxs-institution",
        path: `/institution/${institution ? institution.ie_id : ""}`,
      },
      {
        label: "Registro Estudiantes",
        icon: "bx bxs-user-pin",
        path: "/registration-student",
      },
      {
        label: "Inscripciones",
        icon: "mdi mdi-book-multiple",
        path: "#",
        sw: false,
        subitems: [
          // {
          //   label: 'Nuevo',
          //   icon: 'bx bx-dots-horizontal-rounded',
          //   path: '/inscription-student/new'
          // },
          {
            label: "Nuevo/Antiguo",
            icon: "bx bx-dots-horizontal-rounded",
            path: "/inscription-student/old",
          },
          {
            label: 'Homologacion/Reinc.',
            icon: 'bx bx-dots-horizontal-rounded',
            path: '/inscription-student/homologation'
          },
          
          // {
          //   label: 'Traspaso',
          //   icon: 'bx bx-dots-horizontal-rounded',
          //   path: '/##'
          // },
          {
            label: "Transitabilidad",
            icon: "bx bx-dots-horizontal-rounded",
            path: "/inscription-student/transitabilidad",
          },
        ],
      },
      {
        label: "Docentes/Adm.",
        icon: "bx bxs-user-account",
        path: "/inscription-teacher/list",
      },

      // {
      //   label: 'Oferta Curricular',
      //   icon: 'bx bxs-calendar-alt',
      //   path: '/##'
      // },
      // {
      //   label: 'Notas',
      //   icon: 'bx bxs-file',
      //   path: '/evaluation/score'
      // },
      {
        label: "Paramétricas",
        icon: "mdi mdi-view-quilt",
        path: "#",
        sw: false,
        subitems: [
          {
            label: "Asignaturas",
            icon: "mdi mdi-book-multiple",
            path: "/subject/list",
          },
        ],
      },
      // {
      //   label:'Historial',
      //   icon: 'mdi mdi-view-quilt',
      //   path: '#',
      //   sw:false,
      //   subitems: [
      //     {
      //       label: 'Asignaturas',
      //       icon: 'mdi mdi-book-multiple',
      //       path: '/history'
      //     },
      //   ]
      // },
      {
        label: "Reportes",
        icon: "mdi mdi-view-list",
        path: "/report",
      },
      {
        label: "Historial Académico",
        icon: "mdi mdi-view-list",
        path: "/history",
      },
      // {
      //   label:'Reportes',
      //   icon: 'mdi mdi-view-list',
      //   path: '#',
      //   sw:false,
      //   subitems: [
      //     {
      //       label: 'Carreras',
      //       icon: 'mdi mdi-tag',
      //       path: '/report/career/list'
      //     },
      //     {
      //       label: 'Estudiantes',
      //       icon: 'mdi mdi-tag',
      //       path: '/report/student/list'
      //     },
      //     {
      //       label: 'Historial Académico',
      //       icon: 'mdi mdi-tag',
      //       path: '/report/student/history'
      //     },
      //   ]
      // },
    ];

    // TODO: CODIGO SOLO PARA PRUEBA HASTA SU IMPLEMENTACION by roly

    menu_director.value = kmenu;
  }
};

const loadMenuMaestro = async () => {
  let user = await new Promise((resolve) => {
    resolve(store.state.auth.user);
  });
  if (user) {
    let kmenu = [
      {
        label: "Inicio",
        icon: "mdi mdi-view-dashboard",
        path: `/`,
      },
      {
        label: "Notas",
        icon: "bx bxs-notepad",
        path: `/teacher/notes`,
      },
    ];
    menu_maestro.value = kmenu;
  }
};

const loadMenuEstudiante = async () => {
  let user = await new Promise((resolve) => {
    resolve(store.state.auth.user);
  });
  if (user) {
    let kmenu = [
      {
        label: "Inicio",
        icon: "mdi mdi-view-dashboard",
        path: `/inscription-student/info`,
      },
    ];
    menu_estudiante.value = kmenu;
  }
};

const loadMenuSubdireccion = async () => {
  let user = await new Promise((resolve) => {
    resolve(store.state.auth.user);
  });
  if (user) {
    let kmenu = [
      {
        label: "Trámite Plan de Estudio",
        icon: "pi pi-briefcase",
        path: "#",
        sw: false,
        subitems: [
          {
            label: "Recibidos",
            icon: "bx bxs-inbox",
            path: `/received`,
          },
          {
            label: "Aprobados",
            icon: "bx bxs-file-export",
            path: `/approved`,
          },
          {
            label: "Observados",
            icon: "bx bxs-file-find",
            path: `/observed`,
          },
        ],
      },
      {
        label: "Transitabilidad",
        icon: "pi pi-briefcase",
        path: "#",
        sw: false,
        subitems: [
          {
            label: "Recibidos",
            icon: "bx bxs-inbox",
            path: `/passability/received`,
          },
          {
            label: "Aprobados",
            icon: "bx bxs-file-export",
            path: `/passability/approved`,
          },
          {
            label: "Rechazados",
            icon: "bx bxs-file-find",
            path: `/passability/observed`,
          },
        ],
      },
    
    ];
    menu_subdireccion_educacion_superio.value = kmenu;
  }
};
</script>
<style></style>
